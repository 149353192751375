var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-cate__entire-banner"},_vm._l((Math.ceil(_vm.cate.thumb.length / 2)),function(line,lineIndex){return _c('div',{key:lineIndex,staticClass:"cate-banner-box"},[(!!_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }))?_c('div',{staticClass:"j-cate-two",style:({
        width: _vm.getWidthPercent({
          totalWidth: _vm.getTwoImgThumbWidth({ cate: _vm.cate, lineIndex }),
          width: _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).width,
        }),
      }),attrs:{"title":_vm._f("joinShowUrl")(_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).locationInfo),"data-report-tag":_vm.active.channel +
          '-' +
          _vm.active.oneCate +
          '-' +
          _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).position +
          '-' +
          _vm.cateType,"data-position":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).position,"data-sa-info":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).analysisInfo.saInfo,"data-ga-info":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).analysisInfo.gaInfo,"data-href-type":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).locationInfo.hrefType,"data-jump-type":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).locationInfo.router,"data-href-query":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).locationInfo.query,"data-href-url":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).locationInfo.url,"data-href-target":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).hrefTarget,"data-img-id":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).imgId,"data-map-id":_vm.cate.navNodeId,"data-sa-ps":_vm.generateSaPs({
          position: _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).position,
        }),"data-sacontent":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).analysisInfo.saContent},on:{"click":function($event){_vm.twoCategorySelectedHandler({
          cate: _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }),
          cateType: _vm.cateType,
          event: $event,
        })}}},[_c('a',{attrs:{"href":_vm._f("joinShowUrl")(_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).locationInfo),"aria-hidden":"true","role":"none","tabindex":"-1"},on:{"click":_vm.aTagpreventDefault}},[_c('img',{class:{ lazyload: !_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex })._preloadUrl },style:({
            minWidth: _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).realWidth,
            minHeight: _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).realHeight,
          }),attrs:{"data-src":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex })._target,"data-design-width":_vm.TWO_IMAGE_DESIGN_WIDTH,"data-parent-index":_vm.active.oneCate,"src":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex })._preloadUrl}})])]):_vm._e(),_vm._v(" "),(!!_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }))?_c('div',{staticClass:"j-cate-two",style:({
        width: _vm.getWidthPercent({
          totalWidth: _vm.getTwoImgThumbWidth({ cate: _vm.cate, lineIndex }),
          width: _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).width,
        }),
      }),attrs:{"title":_vm._f("joinShowUrl")(_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).locationInfo),"data-report-tag":_vm.active.channel +
          '-' +
          _vm.active.oneCate +
          '-' +
          _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).position +
          '-' +
          _vm.cateType,"data-position":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).position,"data-sa-info":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).analysisInfo.saInfo,"data-ga-info":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).analysisInfo.gaInfo,"data-href-type":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).locationInfo.hrefType,"data-jump-type":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).locationInfo.router,"data-href-query":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).locationInfo.query,"data-href-url":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).locationInfo.url,"data-img-id":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).imgId,"data-href-target":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).hrefTarget,"data-map-id":_vm.cate.navNodeId,"data-sa-ps":_vm.generateSaPs({
          position: _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).position,
        }),"data-sacontent":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).analysisInfo.saContent},on:{"click":function($event){_vm.twoCategorySelectedHandler({
          cate: _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }),
          cateType: _vm.cateType,
          event: $event,
        })}}},[_c('a',{attrs:{"href":_vm._f("joinShowUrl")(_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 }).locationInfo),"aria-hidden":"true","role":"none","tabindex":"-1"},on:{"click":_vm.aTagpreventDefault}},[_c('img',{class:{ lazyload: !_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 })._preloadUrl },style:({
            minWidth: _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).realWidth,
            minHeight: _vm.getTwoImgThumb({ cate: _vm.cate, lineIndex }).realHeight,
          }),attrs:{"src":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 })._preloadUrl,"data-src":_vm.getTwoImgThumb({ cate: _vm.cate, lineIndex, offset: 1 })._target,"data-design-width":_vm.TWO_IMAGE_DESIGN_WIDTH,"data-parent-index":_vm.active.oneCate}})])]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }