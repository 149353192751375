import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { isInSpa } from 'public/src/pages/components/ccc/common/ccc-link.js'

const useCccLink = (sceneData = {}, propData = {}) => {
  const cccLink =  new CccLink({
    sceneData,
    propData
  })
  const jumpLink = ({ url, hrefType, router, routeType = 'push', cb }) => {
    if (url.indexOf('javascript:;') > -1) return
    if (isInSpa({ hrefType }) && hrefType !== 'activityNew') {
      router[routeType](url)
      cb && cb()
    } else {
      location.href = `${url}${url.indexOf('?') > -1 ? '&allow_cache=1' : '?allow_cache=1' }`
      cb && cb()
    }
  }
  return {
    cccLink,
    jumpLink
  }
}
export default useCccLink
