import { getCurrentInstance, computed } from 'vue'
import { createNamespacedHelpers } from 'vuex'

const useStore = () => {
  const vm = getCurrentInstance().proxy
  if (!vm) throw new Error('must be called in setup')
  return vm.$store
}

const useVuex = (storeModuleName = 'root') => {
  const store = useStore()
  const bindCtx = Object.create({ $store: store })
  const { 
    mapState,
    mapActions,
    mapGetters,
    mapMutations,
  } = createNamespacedHelpers(storeModuleName)

  const useMapState = (state) => {
    const result = {}
    const storeState = mapState(state)
    for (const key in storeState) {
      result[key] = computed(storeState[key].bind(bindCtx))
    }
    return result
  }

  const useMapGetters = (getters) => {
    const result = {}
    const storeGetters = mapGetters(getters)
    for (const key in storeGetters) {
      result[key] = computed(storeGetters[key].bind(bindCtx))
    }
    return result
  }

  const useMapActions = (actions) => {
    const result = {}
    const storeActions = mapActions(actions)
    for (const key in storeActions) {
      result[key] = storeActions[key].bind(bindCtx)
    }
    return result
  }

  const useMapMutations = (mutations) => {
    const result = {}
    const storeMutations = mapMutations(mutations)
    for (const key in storeMutations) {
      result[key] = storeMutations[key].bind(bindCtx)
    }
    return result
  }
  return {
    useMapState,
    useMapGetters,
    useMapActions,
    useMapMutations,
    store,
  }
}

export default useVuex
