import { getCurrentInstance } from 'vue'

const useRouter = () => {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  return {
    router: vm.proxy.$router,
    route: vm.proxy.$route
  }
}

export default useRouter
