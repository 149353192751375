<template>
  <ul class="c-cate__classify-wrap">
    <li
      v-for="(threeCate, threeIndex) in cate"
      :key="threeIndex + threeCate._id"
      class="j-cate-two cate-classify__item"
      :class="{ 'j-expand-title-child': isExpandChild }"
      :title="handleJoinShowUrl(threeCate.locationInfo || {})"
      :data-report-tag="active.channel + '-' + active.oneCate + '-' + threeCate.categoryType.position + '-' + cateType"
      :data-position="threeCate.categoryType.position"
      :data-sa-info="threeCate?.analysisInfo?.saInfo"
      :data-ga-info="threeCate?.analysisInfo?.gaInfo"
      :data-href-type="threeCate?.locationInfo?.hrefType"
      :data-jump-type="threeCate?.locationInfo?.router"
      :data-href-query="threeCate?.locationInfo?.query"
      :data-href-url="threeCate?.locationInfo?.url"
      :data-name="threeCate.categoryName || threeCate.categoryLanguage"
      :data-map-id="threeCate.id"
      :data-img-id="threeCate.imgId || threeCate.cover.imgId"
      :data-isAuto="threeCate.autoRecommend"
      :data-recommend-cate="threeCate.recommendCate"
      :data-href-target="threeCate.categoryType.hrefTarget"
      :data-rec-mark="threeCate.recMack || '-' "
      :data-goodsId="threeCate.goodsId || threeCate.cover.goodsId"
      :data-sa-ps="generateSaPs({ position: threeCate.categoryType.position })"
      :data-sacontent="threeCate?.analysisInfo?.saContent"
      :data-category-id="threeCate.categoryId"
      @click="clickItem({cate: threeCate, index: threeIndex, event: $event})"
      @pointerover="onPointerOver({cate: threeCate, index: threeIndex })"
    >
      <a
        class="cate-classify__wrap"
        tabindex="0"
        role="button"
        :aria-label="threeCate.categoryLanguage"
        @click="handleATagpreventDefault"
      >
        <div
          :key="threeCate._target"
          :class="['cate-classify__img', { lazyload: !threeCate._preloadUrl, 'border': threeCate.recommendCate }, 'fsp-element']"
          :data-background-image="!threeCate._preloadUrl ? threeCate._target : null"
          :data-design-width="!threeCate._preloadUrl ? CLSSIFY_DESIGN_WIDTH : null"
          :style="threeCate._preloadUrl ? { backgroundImage: `url(${threeCate._preloadUrl})` } : {}"
        >
          <em
            v-if="threeCate.angle?.src"
            class="cate-classify__corner-mark lazyload"
            :data-background-image="transformImg({img: threeCate.angle?.src})"
            :data-design-width="CLSSIFY_MARK_DESIGN_WIDTH"
          ></em>
        </div>
        <div 
          class="cate-classify__name"
          :class="{'zIndex': !isActive}"
        >{{ threeCate.categoryLanguage }}</div>
      </a>
    </li>
  </ul>
</template>

<script>
import { CLSSIFY_DESIGN_WIDTH, CLSSIFY_MARK_DESIGN_WIDTH } from 'public/src/pages/product_app/store/modules/category/const.js'
import { transformImg, replaceHrefSpecChar, mergeQueryString, parseQueryString } from '@shein/common-function'
import { getGenerateSaPs } from '../mixin/utils'
import { mapState } from 'vuex'
import cateItemMixin from '../mixin/ccc-cate-item-mixin'
import { preloadGoodsRequest } from 'public/src/pre_requests/modules/productList/spaForHydration.js'
import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'
import Monitor from 'public/src/pages/common/home_common/monitor.js'


// TODO: 1. 改造成jsx 组件 2. 替换成指令式埋点
export default {
  mixins: [cateItemMixin],
  inject: ['isIOS'],
  props: {
    cate: {
      type: Array,
      default () {
        return []
      }
    },
    cateLinks: {
      type: Object,
      default () {
        return {}
      }
    },
    cateProps: {
      type: Object,
      default () {
        return ({})
      }
    },
    active: {
      type: Object,
      default () {
        return {
          channel: '2',
          oneCate: 0
        }
      }
    },
    cateType: {
      type: String,
      default: 'cat'
    },
    contentWidth: {
      type: Number,
      default: 0
    },
    activeParentCatePosition: {
      type: Number,
      default: 0
    },
    activeChildCatePosition: {
      type: Number,
      default: 0
    },
    isChildCate: {
      type: Boolean,
      defualt: false
    },
  },
  data () {
    return {
      CLSSIFY_DESIGN_WIDTH,
      CLSSIFY_MARK_DESIGN_WIDTH,
      isClicked: false,
      isActive: true // 解决Safari部分版本返回到导航页样式错误，触发强制重绘
    }
  },
  computed: {
    ...mapState('category', ['categoryData', 'locals']),
    isExpandChild () {
      const { parentCatNode } = this.cate
      const type = parentCatNode?.style || ''
      return type == 'TEXT_EXTENSIBLE' || type == 'IMAGE_TEXT_EXTENSIBLE'
    },
    localsSite() {
      return this.locals.lang
    },
    // 跳转使用
    cccLink() {
      return new CccLink({
        sceneData: {
          sceneName: this.cateType
        },
        propData: this.cateProps?.child?.[0] || {}
      })
    }
  },
  activated() {
    if (!this.isIOS) return
    // Safari上category切换至list再返回category时，文本省略号会不展示，以下代码触发强制重绘
    this.isActive = false
    this.$nextTick(() => {
      requestAnimationFrame(() => {
        this.isActive = true
      })
    })
  },
  methods: {
    transformImg,

    handleATagpreventDefault ($event) {
      $event && $event.preventDefault()
    },
  
    handleJoinShowUrl(locationInfo) {
      const { url, query } = locationInfo
      return mergeQueryString({
        mergeObj: parseQueryString(query),
        url
      }) || 'javascript:;'
    },
    generateSaPs({ position = 0 }) {
      return getGenerateSaPs({ position, active: this.active, activeParentCatePosition: this.activeParentCatePosition, activeChildCatePosition: this.activeChildCatePosition, isChildCate: this.isChildCate })
    },

    beforeNavChange: function (_href) {
      const cRegx = /.+-c-(\d+).+/
      let cat_id = cRegx.exec(_href)

      if (cat_id) {
        cat_id = cat_id[1]
      }

      if (['ar', 'ar-en', 'in', 'es', 'us', 'de', 'uk', 'fr', 'tw', 'en', 'hk', 'se', 'sesv', 'vn'].includes(this.localsSite)) {
        return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '') })
      } else if (['cl', 'arg', 'ru', 'il', 'ilen', 'th', 'then', 'ca', 'cafr', 'eur', 'eurfr', 'eurde', 'eurnl', 'eurpt-pt'].includes(this.localsSite)) {
        let siteFix = this.localsSite
        if (['th', 'then'].includes(this.localsSite)) siteFix = 'th'
        if (['il', 'ilen'].includes(this.localsSite)) siteFix = 'il'
        if (['ca', 'cafr'].includes(this.localsSite)) siteFix = 'ca'
        if (['eur', 'eurfr', 'eurde', 'eurnl', 'eurpt-pt'].includes(this.localsSite)) siteFix = 'eur'
        // 若链接中已含有站点，则不再添加站点

        const isSiteInclude = (url, site) => {
          const reg = new RegExp(`/${site}/`)
          return reg.test(url)
        }

        if (isSiteInclude(_href, siteFix)) {
          return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '') })
        } else {
          return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '/' + siteFix) })
        }
      }
      return replaceHrefSpecChar({ href: _href })
    },

    addUrlParams ({ url = '', pos = '', id = '' } = {}) {
      const params = {  pageFrom: pos }

      if (id) {
        Object.assign(params, {
          adp: id
        })
      }

      url = mergeQueryString({
        mergeObj: params,
        url
      })
      url = this.beforeNavChange(url)
      url = url && url.replace('%', '')
      return url
    },

    resetIsClicked () {
      setTimeout(() => { this.isClicked = false  }, 300)
    },

    getAnalysisInstance() {
      var parent = this.$parent

      while (parent) {
        if (parent.analysis) {
          break
        }
        parent = parent.$parent
      }
      return parent && parent.analysis
    },
    onPointerOver({ cate, index }) {
      const path = this.generateUrl({ cate, index, isClick: false })
      if (!path) return
      preloadGoodsRequest.triggerNotice(path)
    },

    // @param {Object} isClick 是否是点击事件
    generateUrl({ cate, index, isClick }) {
      const { oneCate = 0 } = this.active || {}
      const categoryType = cate.categoryType
      let url = this.cccLink.getLink({
        item: categoryType, 
        cateLinks: this.cateLinks,
        compIndex: oneCate, 
        index,
      })
      // 推荐图 需要拼接额外参数 goodsId 作为adp 列表页置顶
      if (cate.goodsId) {
        url = this.addUrlParams({ url, pos: this.cateType, id: cate.goodsId })
      }
      // 拼接跳转埋点参数到url 上
      url = mergeQueryString({
        mergeObj: this.getGenerateLLinkParams({ cate, index, cateType: this.cateType }),
        url
      })
      if (isClick) {
        if (cate.categoryType.hrefType != 'noJump') {
          this.$emit('twoCategorySelectedHandler')
        }
        this.jumpLink({ url, hrefType: categoryType?.hrefType })
        return
      }

      return url
    },

    clickItem({ cate, index }) {
      Monitor.metricCategoryClick({
          tags: { 
            page: 'page_category',
            cate_level: '2',
          },
          message: 'Right level second category click'
        })
      const url = this.generateUrl({ cate, index, isClick: true })
      preloadGoodsRequest.click(url)
      const analysis = this.getAnalysisInstance()
      analysis?.twoCateClickHandle({ target: event.currentTarget })
    },
  }
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-new-category {
  .c-cate__classify-wrap {
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0 0 0;
    margin: 0 -0.1067rem;
    background: #fff;

    .cate-classify {
      &__item {
        width: 2.05rem;
        padding: 0 0 0 0;
        margin: 0 0.1067rem 0.4rem;
      }

      &__wrap {
        text-decoration: none;
        display: block;
      }

      &__img {
        width: 1.76rem;
        height: 1.76rem;
        margin: 0 auto;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-color: #f3f3f3;
        &.border {
          border: 0.5px solid #E4E4E4;
        }
      }
      &__corner-mark {
        position: absolute;
        .right(0);
        top: 0;
        width: 0.53rem;
        height: 0.53rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &__name {
        position: relative;
        line-height: 0.3567rem;
        margin-top: 0.0533rem;
        text-align: center;
        font-weight: 400;
        font-size: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        max-height: calc(0.3867rem * 3);
        width: 2.05rem;
        font-size: 0.32rem;
        text-align: center;        
        color: rgba(0, 0, 0, .6);
        padding-bottom: 1px; 
        &.zIndex {
          z-index: 10;
        }
      }
    }
  }
}
</style>
