<template>
  <div 
    class="c-cate__entire-wrap"
    :class="{expand: true}"
  >
    <div
      v-tap="getAnalysisData('1-14-1-23', {item: cate.props, index: compIndex, channel: { ...activeChannel?.channel, index: activeChannel.channelIndex}, oneCateContent: activeLeftContent, oneCate: activeChannel?.oneCate, cateType })"
      v-expose="getAnalysisData('1-14-1-22', {delayReady: 300, item: cate.props, index: compIndex, channel: { ...activeChannel?.channel, index: activeChannel.channelIndex}, oneCateContent: activeLeftContent, oneCate: activeChannel?.oneCate, cateType })" 
      class="j-cate-two c-cate__entire-title"
      :class="{'c-cate__entire-title--first-line': cateItem?.categoryType?.position == 0, 'j-cate-two-expand-title': pos !== 'sidebar' && cate.expandChild && !noExpand}" 
      @click="clickItem" 
    >
      <!-- @click="titleClickHandle({cate, cateType, event: $event})" -->
      <a 
        aria-hidden="true" 
        :style="{color: cate.color}" 
        role="none" 
        tabindex="-1"
        @click="aTagpreventDefault" 
      >
        <div 
          class="c-cate__link-font" 
        >
          <font 
            class="c-cate__link-name"
            :color="'#222'" 
            :style="{ fontWeight: '500' }" 
          >{{ cate?.props?.metaData?.categoryLanguage }}</font>
        </div>
        <div class="c-cate__link-icon">
          <i
            v-if="icon == 1 || icon == -1"
            :class="is_include_critical_css ? 'suiiconfont-critical' : 'suiiconfont'"
            class="title-icon sui_icon_more_right2_16px"
            :style="{visibility: icon != 1 && 'hidden'}"
          ></i>
        </div>
      </a>
    </div>
    <CateClassify 
      v-if="cate.child" 
      :class="{'animate-ready': true && !noExpand, 'no-animate': noExpand}"
      :cate="cate?.child[0]?.props?.items" 
      :cate-props="cate"
      :cate-type="cateType"
      :activeChannel="activeChannel"
      :activeLeftContent="activeLeftContent"
      :compIndex="compIndex"
      :is-child-cate="isChildCate"
      :fs-flag="!!cate.fsFlag"
      @twoCategorySelectedHandler="handleClose"
    />
  </div>
</template>

<script setup>
import CateClassify from './CateClassify.vue'
import { defineProps, computed } from 'vue'
import { mergeQueryString } from '@shein/common-function'
import { getGenerateLLinkParams } from '../utils'
import useAnalysis from '../hooks/useAnalysis'
import useCccLink from '../hooks/useCccLink'
import useRouter from '../hooks/useRouter'
import Monitor from 'public/src/pages/common/home_common/monitor.js'

const { getAnalysisData } = useAnalysis()
const { router } = useRouter() 
const { cccLink, jumpLink } = useCccLink({
  sceneData: {
    sceneName: props.cateType
  },
  propData: props.cate || {},
})

const props = defineProps({
  cate: {
    type: Object,
    default: () => ({})
  },
  cateType: {
    type: String,
    default: ''
  },
  activeChannel: {
    type: Object,
    default: () => ({})
  },
  activeLeftContent: {
    type: Object,
    default: () => ({})
  },
  compIndex: {
    type: Number,
    default: 0
  },
  cateLinks: {
    type: Object,
    default: () => ({})
  },
  pos: {
    type: String,
    default: ''
  },
  noExpand: {
    type: Boolean,
    default: false
  },
  isChildCate: {
    type: Boolean,
    default: false
  },
  handleClose: {
    type: Function,
    default: () => {}
  }
})
const cateItem = computed(() => props?.cate?.props?.metaData)
const icon = computed(() => {
  if (props?.cateType === 'sidebar') {
    return -1
  }
  if (props?.cate.props?.metaData?.categoryType?.hrefType !== 'noJump') {
    return 1
  }
  return 0
})
const is_include_critical_css = typeof window !== 'undefined' ? gbCommonInfo.is_include_critical_css : true

const clickItem = () => {
  const categoryType = props.cate?.props?.metaData?.categoryType

  let url = cccLink.getLink({
    item: categoryType, 
    cateLinks: {},
    compIndex: props.compIndex, 
    index: -1,
  })
  // 拼接跳转埋点参数到url 上
  url = mergeQueryString({
    mergeObj: getGenerateLLinkParams({ 
      cate: props.cate?.props?.metaData,
      cateType: props.cateType, 
      channel: props.activeChannel?.channel,
      channelIndex: props.activeChannel?.channelIndex,
      oneCateContent: props.activeLeftContent,
      compIndex: props.compIndex,
      oneCate: props.activeChannel?.oneCate,
    }),
    url
  })
  Monitor.metricCategoryClick({
    tags: { 
      page: 'page_category',
      cate_level: '2',
    },
    message: 'Right level second category click'
  })
  if (categoryType.hrefType != 'noJump') {
    props.handleClose()
  } 
  jumpLink({ url, hrefType: categoryType?.hrefType, router })

}
const aTagpreventDefault = (e) => {
  e.preventDefault()
  
}

</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-new-category {
  .c-cate__entire-wrap {
    overflow: hidden;
    min-height: 1.253rem;
    .animate-ready {
      transition: .3s margin-top cubic-bezier(0.2, 0.63, 0.57, 0.94);
    }
    .c-cate__classify-wrap:not('.no-animate') {
      opacity: 0;
      animation-name: opacity-leave;
      animation-timing-function: ease;
      animation-duration: .3s;
      animation-fill-mode: forwards;
      // animation-play-state: running;
    }
    &.expand {
        .title-banner {
            opacity: 0;
        }
    }
    @keyframes opacity-enter {
        0% {
            opacity: 0;
        }
        20% {
            opacity: .2;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes opacity-leave {
        0% {
            opacity: 1;
        }
        30% {
            opacity: .2;
        }
        100% {
            opacity: 0;
        }
    }
    .c-cate {
      &__entire-title {
        z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
        font-weight: normal;
        color: #222;
        display: flex;
        align-items: center;
        min-height: 0.855rem;
        justify-content: space-between;
        .font-dpr(20px);
        line-height: .32rem;
        .txt-l();

        .title-icon {
            .fr();
            color: #999;
            font-size: 12px;
        }

        width: 100%;
        margin-top: 0;
        padding: 0 0 0 0;
        background: #fff;
        a {
            display: flex;
            justify-content: space-between;
            flex: 1;
            overflow: hidden;
        }
        .title-icon {
            transition: .3s all ease;
            &.expand {
                transform: rotateZ(180deg);
            }
        }
      }
      &__link-font {
        font-size: 0.32rem;
        display: flex;
        color: #222;
        line-height: 0.855rem;
        transition: .3s all cubic-bezier(0.2, 0.63, 0.57, 0.94);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &__link-count {
          margin-left: 0.053rem;
          font-size: 0.267rem;
          zoom: .95;
          color: #959595;
      }
      &__link-name {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &__link-icon {
        display: flex;
        align-items: center;
        .title-banner {
            height: 1.093rem;
            width: 1.093rem;
            margin: 0.08rem 0.32rem;
            transition: .3s opacity cubic-bezier(0.2, 0.63, 0.57, 0.94);
        }
      }
      &__entire-title--first-line {
          margin-top: 0;
      }
    }

    &.sidebar-pop {
      .c-cate {
        &__entire-title {
          // height: unset;
          // line-height: unset;
          font-size: 0.267rem;

        }
      }
    }
  }
}
</style>
