<template>
  <div>
    <div class="cate-new_swiper-wrapper">
      <swiper-container
        ref="swiper"
        class="cate-new_swiper"
        init="false"
        destroy-on-disconnected="false"
      >
        <swiper-slide
          v-for="(item, i) in carouselList"
          :key="i"
          v-tap="getAnalysisData('1-14-1-23', {item, index: compIndex, channel: { ...activeChannel?.channel, index: activeChannel.channelIndex}, oneCateContent: activeLeftContent, oneCate: activeChannel?.oneCate, cateType })" 
          v-expose="getAnalysisData('1-14-1-22', {delayReady: 300, item, index: compIndex, channel: { ...activeChannel?.channel, index: activeChannel.channelIndex}, oneCateContent: activeLeftContent, oneCate: activeChannel?.oneCate, cateType })" 
          class="cate-new_swiper-slide j-cate-two"
          @click="event => clickSlide(event, item)"
        >
          <BaseImg
            class="base-img"
            :class="{
              'cate-swiper-img': true
            }"
            :placeholder="{
              width: item.image.width,
              height: item.image.height,
            }"
            :custom-ratio="getCustomRatio(item)"
            :style="bgStyle"
            :ratio="item.image.ratio"
            :img-src="item.image.src"
            img-design-width="950"
            :ada="item.ada"
            :alt="item.hrefTitle"
            :brand="brand"
            :first-screen="i < 1"
          />
        </swiper-slide>
        <div
          v-if="carouselList.length > 1"
          slot="pagination"
          :class="`j-ccc-swiper-pagination-${index}`"
        ></div>
      </swiper-container>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, computed, onMounted } from 'vue'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { register } from 'swiper/element'
import { Pagination, Autoplay } from 'swiper/modules'
typeof window !== 'undefined' && register()
const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
import { mergeQueryString } from '@shein/common-function'
import { getGenerateLLinkParams } from '../utils'
import expose from 'public/src/services/expose/index.js'
import Monitor from 'public/src/pages/common/home_common/monitor.js'

import useAnalysis from '../hooks/useAnalysis'
import useCccLink from '../hooks/useCccLink'
import useRouter from '../hooks/useRouter'

const { getAnalysisData } = useAnalysis()
const { router } = useRouter() 
const { cccLink, jumpLink } = useCccLink({
  sceneData: {
    sceneName: props.cateType
  },
  propData: props.cate || {},
})

const props = defineProps({
  cate: {
    type: Object,
    default() {
      return {}
    },
  },
  cardStyle: {
    type: Object,
    default() {
      return {}
    },
  },
  activeChannel: {
    type: Object,
    default: () => ({})
  },
  activeLeftContent: {
    type: Object,
    default: () => ({})
  },
  cateType: {
    type: String,
    default: ''
  },
  brand: {
    type: String,
    default: '',
  },
  compIndex: {
    type: Number,
    default: 0
  },
  handleClose: {
    type: Function,
    default: () => {}
  },
  index: {
    type: Number,
    default: 0
  },
})
const swiper = ref(null)
const bgStyle = computed(() => {
  return {
    background: '#e5e5e5',
    borderRadius: props.cardStyle.borderRadius || '0px',
  }
})
const carouselList = computed(() => {
  let temp = props.cate?.props?.items || []
  if (props.cate?.props?.metaData?.carouselWay === 'optimization') {
    const totalWeights = temp.reduce((acc, cur) => acc + Number(cur.weights || 0), 0)
    temp.forEach(i => {
      i.weights = i.weights / totalWeights + Math.random()
    })
    temp.sort((a, b) => b.weights - a.weights)
  } else if (props.cate?.props?.metaData?.carouselWay === 'random') {
    temp.sort(() => Math.round(Math.random()) * 2 - 1)
  }
  return temp || []
})
const clickSlide = (event, imageItem) => {
  let url = cccLink.getLink({
    item: imageItem?.categoryType, 
    cateLinks: {},
    compIndex: props.compIndex, 
    index: -1,
  })
  // 拼接跳转埋点参数到url 上
  url = mergeQueryString({
    mergeObj: getGenerateLLinkParams({ 
      cate: imageItem,
      cateType: props.cateType, 
      channel: props.activeChannel?.channel,
      channelIndex: props.activeChannel?.channelIndex,
      oneCateContent: props.activeLeftContent,
      compIndex: props.compIndex,
      oneCate: props.activeChannel?.oneCate,
    }),
    url
  })
  Monitor.metricCategoryClick({
    tags: { 
      page: 'page_category',
      cate_level: '2',
    },
    message: 'Right level second category click'
  })
  if (imageItem.categoryType.hrefType != 'noJump') {
    props?.handleClose && props?.handleClose()
  }
  jumpLink({ url, hrefType: imageItem?.categoryType?.hrefType, router })
}
const initSwiper = () => {
  if (typeof window === 'undefined') return
  const swiperEl = swiper.value
  if (!swiperEl) return
  const swiperParams = {
    modules: [Pagination, Autoplay],
    injectStylesUrls: [ PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'],
    injectStyles: [
      `
      .swiper-pagination{
        position: absolute;
      }
      .swiper-pagination .swiper-pagination-bullet-active{
        background: rgba(34,34,34,.8);
        border: 1px solid hsla(0,0%,100%,.8);
      }
      .swiper-pagination .swiper-pagination-bullet{
        width: 6px;
        height: 6px;
      }
    `,
    ],
    loop: true,
    pagination: {
      hideOnClick: false,
    },
    spaceBetween: 0,
    speed: 300,
    slidesPerView: 1,
    slidesPerGroup: 1,
    allowTouchMove: true,
    autoplay: true,
    enabled: true,
    on: {
      init: () => {
        observeSwiper()
      },
    }
  }
  Object.assign(swiperEl, swiperParams)
  swiperEl.initialize()

  if (window.requestIdleCallback) {
    requestIdleCallback(() => {
      if (!swiperEl.initialized) {
        swiperEl.initialize()
      }
    })
  } else {
    setTimeout(() => {
      if (!swiperEl.initialized) {
        swiperEl.initialize()
      }
    }, 10)
  }
}
const getCustomRatio = (item) => {
  const { width, height } = item.image
  return `${(height * 100 / width)}%`
}
// 是否展示轮播
const observeSwiper = () => {
  const obInstance = new expose({ observeHide: true })
  const el = document.querySelector('.cate-new_swiper-wrapper')
  if (!el) return
  obInstance.observe({
    elements: el,
    once: false,
  }, ({ exposeDoms }) => {
    if (exposeDoms.length) {
      swiper?.value?.swiper?.autoplay?.start?.()
    } else {
      swiper?.value?.swiper?.autoplay?.pause?.()        }
  })
}

onMounted(() => {
  initSwiper()
})

</script>
  <style lang="less" scoped>
  .cate-new_swiper-wrapper {
    margin-top: .32rem;
    width: 100%;
    overflow: hidden;
    margin-bottom: -1px;
    /* 设置 Swiper 容器样式 */
    .cate-new_swiper {
      display: flex;
      flex-wrap: nowrap;
    }
    /* 设置滑块 slide 样式 */
    .cate-new_swiper-slide {
      --slides-per-view: 1;
      width: calc(100% / var(--slides-per-view));
      flex-shrink: 0;
  
    }
  
    .base-img{
      width: 100%;
    }
  }
  </style>
  
