<template>
  <div>
    <cate-slider-long
      v-if="cate.style.type === 'SECOND_IMAGE_CAROUSEL_LONG'"
      :cate="cate"
      :index="index"
      :active="active"
      :cate-type="cateType"
      :content-width="contentWidth"
      :activeParentCatePosition="activeParentCatePosition"
      :activeChildCatePosition="activeChildCatePosition"
      :isChildCate="isChildCate"
      v-on="$listeners"
      @updateContent="updateContent"
    />
    <cate-slider-stack
      v-if="cate.style.type === 'SECOND_IMAGE_CAROUSEL_SHORT'"
      :cate="cate"
      :index="index"
      :active="active"
      :cate-type="cateType"
      :content-width="contentWidth"
      :activeParentCatePosition="activeParentCatePosition"
      :activeChildCatePosition="activeChildCatePosition"
      :isChildCate="isChildCate"
      v-on="$listeners"
      @updateContent="updateContent"
    />
  </div>
</template>

<script>
import cateSliderStack from './cate-slider-stack'
import cateSliderLong from './cate-slider-long'
export default {
  components: {
    cateSliderStack,
    cateSliderLong
  },
  props: {
    cate: {
      type: Object,
      default () {
        return {}
      }
    },
    active: {
      type: Object,
      default () {
        return {
          channel: '2',
          oneCate: 0
        }
      }
    },
    cateType: {
      type: String,
      default: 'cat'
    },
    contentWidth: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    activeParentCatePosition: {
      type: Number,
      default: 0
    },
    activeChildCatePosition: {
      type: Number,
      default: 0
    },
    isChildCate: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    updateContent () {
      this.$emit('updateContent')
    }
  }
}
</script>
