<template>
  <div
    v-if="cardArrs && cardArrs.length > 0"
    class="cate-slider-stack"
    :class="{'cate-slider-stack-ar': isReverse}"
    :style="{margin: index !== 1 ? '.32rem 0' : ''}"
    @touchstart="playerTouchStart"
    @touchmove="playerTouchMove"
    @touchend="playerTouchEnd"
    @mousedown="playerTouchStart"
    @mousemove="playerTouchMove"
    @mouseup="playerTouchEnd"
  >
    <div
      class="card-wrapper"
      :style="{minWidth: cardArrs[0].realWidth, minHeight: cardArrs[0].realHeight}"
    >
      <div
        v-for="(item, _index) in cardArrs"
        ref="cate-slider__slider"
        :key="item._target"
        class="card-item j-cate-two-slider"
        :class="[_index === currentIndex ? 'j-cate-two-slider-active' : '']"
        :style="item.zIndex ? {
          zIndex: item.zIndex,
          transform: `scale(${item.scale}) translateX(${item.translateX})`,
          transition: `transform ${item.transitionTime}s ease 0s`,
          visibility: _index != 0 && item.zIndex === undefined ? 'hidden' : ''
        } : { visibility: _index != 0 ? 'hidden' : ''}"
        :title="item.locationInfo | joinShowUrl"
        :data-report-tag="active.channel + '-' + active.oneCate + '-' + item.position + '-' + cateType"
        :data-position="item.position"
        :data-sa-info="item.analysisInfo.saInfo"
        :data-ga-info="item.analysisInfo.gaInfo"
        :data-href-type="item.locationInfo.hrefType"
        :data-jump-type="item.locationInfo.router"
        :data-href-query="item.locationInfo.query"
        :data-href-url="item.locationInfo.url"
        :data-href-target="item.hrefTarget"
        :data-map-id="item.navNodeId"
        :data-img-id="item.imgId"
        :data-sa-ps="generateSaPs({ position: item.position })"
        :data-sacontent="item.analysisInfo.saContent"
        @click="twoCategorySelectedHandler({cate: item, cateType, event: $event})"
      >
        <a
          v-show="!item.hidden"
          :href="item.locationInfo | joinShowUrl"
          class="cate-slider-stack__img"
          aria-hidden="true"
          role="none"
          tabindex="-1"
          @click="aTagpreventDefault"
        >
          <div :style="{width: getWidthPercent({totalWidth: item.width, width: item.width})}">
            <img
              :style="{minWidth: cardArrs[0].realWidth, minHeight: cardArrs[0].realHeight}"
              :class="{ lazyload: !item._preloadUrl }"
              :data-src="!item._preloadUrl && item._target"
              :data-design-width="!item._preloadUrl && SLIDER_STACK_DESIGN_WIDTH"
              :src="item._preloadUrl"
              :data-parent-index="active.oneCate"
            />
          </div>
        </a>
      </div>
    </div>
    <div class="card-pagenation">
      <template v-for="(item, index_) in cardArrs">
        <span
          :key="index_"
          :class="{'page-current': item.scale === 1}"
        ></span>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import cateItemMixin from '../mixin/ccc-cate-item-mixin'
import { SLIDER_STACK_DESIGN_WIDTH } from 'public/src/pages/product_app/store/modules/category/const.js'

export default {
  mixins: [cateItemMixin],
  props: {
    cate: {
      type: Object,
      default () {
        return {}
      }
    },
    active: {
      type: Object,
      default () {
        return {
          channel: '2',
          oneCate: 0
        }
      }
    },
    cateType: {
      type: String,
      default: 'cat'
    },
    contentWidth: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      SLIDER_STACK_DESIGN_WIDTH,
      cardArrs: [],
      indexList: [0, 1, 2, 3, 4],
      xList: null,
      isClick: true,
      startX: 0, // 触摸位置
      endX: 0, // 结束位置
      moveX: 0, // 滑动时的位置
      disX: 0, // 移动距离
      slideDistance: 40, // 滑动触发切换还是回位的阀值
      currentIndex: 0, // 当前第一个的 index
      slideDirection: 0, // 滑动方向：0 向右，1 向左
      slideFilishDistance: 300, // 滑动切换动画完成的距离
      timer: null,
      isReverse: false
      // paddingBottom: ''
    }
  },
  computed: {
    ...mapState('category', {
      localsGBCssRight: state => state.locals.GB_cssRight
    }),
  },
  watch: {
    currentIndex: {
      handler () {
        this.$nextTick(() => {
          this.$emit('updateContent')
        })
      }
    }
  },
  created() {
    this.cardArrs = this.cate && this.cate.thumb
  },
  mounted () {
    if (this.localsGBCssRight) {
      this.isReverse = true
      this.xList = new Map([[0, '0'], [1, '-0.56rem'], [2, '-1.28rem'], [3, '-2.1867rem'], [4, '-2.1867rem']])
    } else {
      this.xList = new Map([[0, '0'], [1, '0.56rem'], [2, '1.28rem'], [3, '2.1867rem'], [4, '2.1867rem']])
    }
    this.indexList.forEach((item, index) => {
      const data = this.cardArrs[item]
      if (data) {
        this.$set(data, 'translateX', this.xList.get(index))
        this.$set(data, 'transitionTime', 1)
        this.$set(data, 'scale', 1 - index / 10)
        this.$set(data, 'zIndex', 10 - index)
      } else {
        this.xList.delete(index)
      }
    })
    this.indexList.splice(this.cardArrs.length)
    if (this.cate.style.carouselType === 'auto') {
      this.startTimer(this.cate.style.time * 1000)
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    startTimer (t) {
      this.timer = setInterval(() => {
        this.autoPlayHandle()
      }, t)
    },
    sleep (t) {
      return new Promise(function (resolve) {
        setTimeout(resolve, t)
      })
    },
    async autoPlayHandle () {
      this.slideDirection = 1
      const item = this.cardArrs[this.currentIndex]
      item.translateX = this.localsGBCssRight ? '6rem' : '-6rem'
      item.transitionTime = 1
      item.scale = 1
      this.sleep(500).then(() => {
        this.slideLeft()
      })
    },
    // 滑动开始
    playerTouchStart (ev) {
      this.clearTimer()
      ev = ev || event
      this.isClick = true
      // tounches类数组，等于1时表示此时有只有一只手指在触摸屏幕
      if (ev.touches.length === 1) {
        // 记录开始位置
        this.startX = ev.touches[0].clientX
      }
    },
    // 滑动中
    playerTouchMove (ev) {
      ev = ev || event
      this.isClick = false
      if (ev.touches && ev.touches.length === 1) {
        // 滑动时距离浏览器左侧实时距离
        this.moveX = ev.touches[0].clientX
        // 起始位置减去实时的滑动的距离,得到手指实时偏移距离
        this.disX = this.startX - this.moveX
        // 判断滑动方向
        if ((this.disX < 0 && !this.localsGBCssRight) || (this.disX > 0) && this.localsGBCssRight) {
          // 向右滑
          this.slideDirection = 0
          // 当前第一个变化
          const item = this.cardArrs[this.currentIndex]
          if (-this.disX <= this.slideFilishDistance) {
            item.translateX = !this.localsGBCssRight ? '0' : '0'
            // -(4 / this.slideFilishDistance) * this.disX + "vw";
            item.transitionTime = 0
            item.scale = 1
          }
          this.indexList.forEach((item, index) => {
            const data = this.cardArrs[item]
            data.hidden = false
            if (index === this.indexList.length - 1) {
              data.hidden = true
              data.translateX = !this.localsGBCssRight ? '-6rem' : '6rem'
              // -(4 / this.slideFilishDistance) * this.disX + "vw";
              data.transitionTime = 0
              data.scale = 1
            }
          })
        } else if ((this.disX > 0 && !this.localsGBCssRight) || (this.disX < 0) && this.localsGBCssRight) {
          // 向左滑
          this.slideDirection = 1
          // 当前第一个变化
          const item = this.cardArrs[this.currentIndex]
          item.translateX = -this.disX - 24 + 'px'
          item.transitionTime = 0
          item.scale = 1
        }
      }
    },
    // 滑动结束
    playerTouchEnd (ev) {
      ev = ev || event
      if (ev.changedTouches.length === 1) {
        this.endX = ev.changedTouches[0].clientX
        this.disX = this.startX - this.endX
        const bool = this.cardArrs.some(item => item.hidden)
        if (Math.abs(this.disX) < this.slideDistance && !bool) {
          // 滑动距离小于滑动限制的距离,强行回到起点
          this.returnBack()
        } else {
          // 滑动距离大于滑动限制的距离,滑动到最大值
          if (this.slideDirection === 1) {
            this.slideLeft()
          } else {
            this.slideRight()
          }
        }
      }
      if (this.cate.style.carouselType === 'auto') {
        this.startTimer(this.cate.style.time * 1000)
      }
    },
    // 回到起点
    returnBack () {
      this.indexList.forEach((item, index) => {
        const data = this.cardArrs[item]
        data.translateX = this.xList.get(index)
        data.transitionTime = 1
        data.scale = 1 - index / 10
        data.zIndex = 10 - index
        data.hidden = false
      })
      this.currentIndex = this.indexList[0]
    },
    // 向左滑动切换
    slideLeft () {
      this.indexList.push(this.currentIndex)
      this.indexList.shift()
      this.indexList.forEach((item, index) => {
        const data = this.cardArrs[item]
        if (data) {
          data.translateX = this.xList.get(index)
          data.transitionTime = 0.5
          data.scale = 1 - index / 10
          data.zIndex = 10 - index
          data.hidden = false
        }
      })
      this.currentIndex = this.indexList[0]
    },
    // 向右滑动切换
    slideRight () {
      this.indexList.unshift(this.indexList[this.indexList.length - 1])
      this.indexList.pop()
      this.indexList.forEach((item, index) => {
        const data = this.cardArrs[item]
        data.translateX = this.xList.get(index)
        data.transitionTime = 0.5
        data.scale = 1 - index / 10
        data.zIndex = 10 - index
        data.hidden = false
      })
      this.currentIndex = this.indexList[0]
    },
    clearTimer () {
      clearInterval(this.timer)
      this.timer = null
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.cate-slider-stack {
    width: 100%;
    height: 100%;
    position: relative;
    padding:0 0.64rem 0 0;
    margin-top: 0.32rem;
    .card-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        .card-item {
            width: 100%;
            height: 100%;
            position: absolute;
            text-align: center;
            font-size: 0.24rem;
            will-change: transform;
            img{
                width: 100%;
                object-fit: cover;
                position: absolute;
                left: 0/*rtl:ignore*/;
                top: 0;
            }
        }
    }
    .card-pagenation{
        position: absolute;
        bottom: 10%;
        left: 50%/*rtl:ignore*/;
        transform: translateX(-50%)/*rtl:ignore*/;
        z-index: 20; /* stylelint-disable-line declaration-property-value-blacklist */
        span{
            display: inline-block;
            width: 0.187rem;
            height: 0.187rem;
            border-radius: 50%;
            margin: 0 0.08rem;
            background: #fff;
            border:1px solid rgba(0,0,0,0.2);
        }
        .page-current{
            width: 0.187rem;
            height: 0.187rem;
            background: #222;
            border:1px solid rgba(255,255,255,0.8);
        }
    }
}
.cate-slider-stack-ar{
    padding:0 0 0 0.64rem;
}
</style>
