<template>
  <!-- @touchmove.stop.prevent -->
  <s-tab
    v-model="selectTab"
    :type="'auto'"
    @change="handleTabChange"
  >
    <s-tab-item
      v-for="(item, key, index) in tabData"
      :id="item.channel_type"
      :key="key"
      v-expose="{ 
        code: 'categoryTopCate',
        id: '1-14-1-18', 
        prefix: pageCode,
        delayReady: 1500,
        data: {
          item,
          index,
          crowdId: categoryCrowdInfo.crowdId,
        } 
      }"
      :data-index="index"
      :data-name="item.channelName || item.name"
      :data-channel-type="item.channel_type"
      :data-auto-map="item.autoMap"
      :data-top-category="`${index + 1}\`${item.channelName || item.name}\`${item.channel_type}\`${categoryCrowdInfo.crowdId || 0}`"
      class="j-c-content__tab-item"
      :class="[`j-c-content__tab-item-${item.channel_type}`, selectTab == item.channel_type ? 'j-sa-active-topcategory' : '']"
      :aria-label="item.name"
      role="button"
      tabindex="0"
    >
      {{ item.name }}
    </s-tab-item>
  </s-tab>
</template>

<script>
import { mapState } from 'vuex'
import { Tab, TabItem } from '@shein/sui-mobile'

export default {
  name: 'CategoryHeaderTab',
  components: {
    STab: Tab,
    STabItem: TabItem,
  },
  props: {
    type: {
      default: () => 'cat',
      type: String
    },
    tabData: {
      type: Object,
      default () {
        return {}
      }
    },
    activeKey: {
      type: String || Number,
      default: '2'
    },
    categoryCrowdInfo: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data(){
    return {
      selectTab: this.activeKey,
    }
  },
  computed: {
    ...mapState('category', ['pageCode']),
  },
  watch: {
    activeKey(val) {
      this.selectTab = val
    }
  },
  methods: {
    selectedHandler (id) {
      this.selectTab = id
    },
    handleTabChange(data) {
      if (!data.oldVal) return // 首次进入页面不触发
      let item
      for (var i in this.tabData) {
        if (this.tabData[i].channel_type == data.val) {
          item = this.tabData[i]
        }
      }
      this.$emit('selectedHandler', { item, target: document.querySelector(`.j-c-content__tab-item-${data.val}`) })
    }
  }
}
</script>
