<script lang="jsx">
export default {
  name: 'SkeletonHeader',
  functional: true,
  render() {
    return (
      <header class='c-skeleton__tab-wrap'>
        <a class='c-skeleton__tab-item'></a>
        <a class='c-skeleton__tab-item'></a>
        <a class='c-skeleton__tab-item'></a>
        <a class='c-skeleton__tab-item'></a>
      </header>
    )
  },
}
</script>

<style lang="less">
.c-skeleton {
  &__tab-wrap {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 1.1733rem;
    border-bottom: 1px solid #e5e5e5;
    background: #fff;
    overflow: hidden;
  }

  &__tab-item {
    height: 0.52rem;
    margin: 0 0.32rem;
    background: #f5f5f5;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
  }
}
</style>
