<template>
  <div 
    class="c-cate__entire-wrap" 
    :class="{expand: true}"
  >
    <div
      class="j-cate-two c-cate__entire-title"
      :class="{'c-cate__entire-title--first-line': cateItem?.categoryType?.position == 0, 'j-cate-two-expand-title': pos !== 'sidebar' && cate.expandChild && !noExpand}"
      :title="(cateItem?.locationInfo || {}) | joinShowUrl"
      :data-expand="cate.expand"
      :data-report-tag="active.channel + '-' + active.oneCate + '-' + cateItem?.categoryType?.position + '-' + cateType"
      :data-position="cateItem?.categoryType?.position "
      :data-sa-info="cateItem?.analysisInfo?.saInfo"
      :data-ga-info="cateItem?.analysisInfo?.gaInfo"
      :data-href-type="cateItem?.locationInfo?.hrefType"
      :data-jump-type="cateItem?.locationInfo?.router"
      :data-href-query="cateItem?.locationInfo?.query"
      :data-href-url="cateItem?.locationInfo?.url"
      :data-href-target="cateItem?.categoryType?.hrefTarget"
      :data-name="cateItem?.categoryName || cateItem?.categoryLanguage"
      data-rec-mark="-"
      :data-map-id="cateItem?.props?.id"
      :data-child-id="cate.expandChild && cate.expandChild.id"
      :data-child-name="cate.expandChild && cate.expandChild.name"
      :data-sa-ps="generateSaPs({ position: cateItem?.categoryType?.position })"
      :data-sacontent="cateItem?.analysisInfo?.saContent"
      :data-category-id="cateItem?.categoryId"
      @click="clickItem" 
    >
      <!-- @click="titleClickHandle({cate, cateType, event: $event})" -->
      <a 
        aria-hidden="true" 
        :style="{color: cate.color}" 
        role="none" 
        tabindex="-1"
        @click="aTagpreventDefault" 
      >
        <div 
          class="c-cate__link-font" 
        >
          <font 
            class="c-cate__link-name"
            :color="'#222'" 
            :style="{ fontWeight: '500' }" 
          >{{ cate?.props?.metaData?.categoryLanguage }}</font>
        </div>
        <div class="c-cate__link-icon">
          <i
            v-if="icon == 1 || icon == -1"
            :class="is_include_critical_css ? 'suiiconfont-critical' : 'suiiconfont'"
            class="title-icon sui_icon_more_right2_16px"
            :style="{visibility: icon != 1 && 'hidden'}"
          ></i>
          <!-- <i
            v-if="icon == 0"
            :class="[cate.expand ? 'expand' : '', is_include_critical_css ? 'suiiconfont-critical' : 'suiiconfont']"
            class="title-icon sui_icon_more_down2_16px"></i> -->
        </div>
      </a>
    </div>
    <cate-new-classify 
      v-if="cate.child" 
      :style="style" 
      :class="{'animate-ready': animateReady && !noExpand, 'no-animate': noExpand}"
      :cate="cate?.child[0]?.props?.items" 
      :cate-props="cate"
      :cate-links="cateLinks"
      :cate-type="cateType"
      :active="active" 
      :active-parent-cate-position="activeParentCatePosition" 
      :active-child-cate-position="activeChildCatePosition" 
      :is-child-cate="isChildCate"
      @twoCategorySelectedHandler="handleClose"
    />
  </div>
</template>

<script>
import cateItemMixin from '../mixin/ccc-cate-item-mixin'
import cateNewClassify from './cate-new-classify.vue'
import { TITLE_DESIGN_WIDTH } from 'public/src/pages/product_app/store/modules/category/const.js'
import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { mergeQueryString } from '@shein/common-function'

export default {
  components: { cateNewClassify },
  mixins: [cateItemMixin],
  props: {
    cate: {
      type: Object,
      default () {
        return {}
      }
    },
    cateLinks: {
      type: Object,
      default () {
        return {}
      }
    },
    active: {
      type: Object,
      default () {
        return {
          channel: '2',
          oneCate: 0
        }
      }
    },
    cateType: {
      type: String,
      default: 'cat'
    },
    pos: {
      default: '',
      type: String
    },
    handleClose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      TITLE_DESIGN_WIDTH,
      is_include_critical_css: typeof window !== 'undefined' ? gbCommonInfo.is_include_critical_css : true,
      style: {},
      animateReady: true
    }
  },
  computed: {
    // （1 ｜｜ -1） right 0 down
    icon() {
      if (this.pos == 'sidebar') return -1
      if (this.cate?.props?.metaData?.categoryType?.hrefType !== 'noJump') return 1
      // if (this.noExpand) return -1
      return 0
    },
    noExpand() {
      const style = this.cate.style || {}
      return style.type !== 'TEXT_EXTENSIBLE' && style.type !== 'IMAGE_TEXT_EXTENSIBLE'
    },
    locationInfo() {
      return this.cate?.child && this.cate?.child[0]?.props?.items[0] || {}
    },
    cateItem() {
      return this.cate?.props?.metaData
    },
    // 跳转使用
    cccLink() {
      return new CccLink({
        sceneData: {
          sceneName: this.cateType
        },
        propData: this.cate || {},
      })
    }
  },
  methods: {
    // titleClickHandle({ cate, cateType, event }) {
    //   if(this.pos !== 'sidebar') {
    //     this.animateReady = !this.cate.show ? true : !this.cate.expand
    //     this.cate.show = !this.cate.show
    //     this.$nextTick(() => {
    //       this.cate.expand = !this.cate.expand
    //       this.setStyle()
    //       this.$emit('toggleSubCatExpand', {cate: this.cate})
    //     })
    //   }
    //   this.twoCategorySelectedHandler({ cate: this.locationInfo, cateType, event })
    // },
    setStyle() {
      const classIfyHeight = this.$el.querySelector('.c-cate__classify-wrap')?.offsetHeight || 0
      const classTitleHeight = this.$el.querySelector('.c-cate__entire-title')?.offsetHeight || 0

      this.style = this.noExpand ? {
        marginTop: '0px'
      } : {
        marginTop: !this.cate.expandChild ? '' : this.cate.expand ? '' : `-${classIfyHeight + classTitleHeight}px`
      }
    },
    clickItem(event) {
      if (event) {
        const analysis = this.getAnalysisInstance()
        analysis?.twoCateClickHandle({ target: event.currentTarget })
      }
      const { oneCate = 0 } = this.active || {}
      const categoryType = this.cate?.props?.metaData?.categoryType
      let url = this.cccLink.getLink({
        item: categoryType, 
        cateLinks: this.cateLinks,
        compIndex: oneCate, 
        index: -1,
      })
      // 拼接跳转埋点参数到url 上
      url = mergeQueryString({
        mergeObj: this.getGenerateLLinkParams({ cate: this.cate?.props?.metaData, cateType: this.cateType }),
        url
      })
      // this.handleClose && this.handleClose()
      if (categoryType.hrefType != 'noJump') {
        this.handleClose()
      }
      this.jumpLink({ url, hrefType: categoryType?.hrefType })
    },
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-new-category {
  .c-cate__entire-wrap {
    overflow: hidden;
    min-height: 1.253rem;
    .animate-ready {
      transition: .3s margin-top cubic-bezier(0.2, 0.63, 0.57, 0.94);
    }
    .c-cate__classify-wrap:not('.no-animate') {
      opacity: 0;
      animation-name: opacity-leave;
      animation-timing-function: ease;
      animation-duration: .3s;
      animation-fill-mode: forwards;
      // animation-play-state: running;
    }
    &.expand {
        .title-banner {
            opacity: 0;
        }
    }
    @keyframes opacity-enter {
        0% {
            opacity: 0;
        }
        20% {
            opacity: .2;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes opacity-leave {
        0% {
            opacity: 1;
        }
        30% {
            opacity: .2;
        }
        100% {
            opacity: 0;
        }
    }
    .c-cate {
      &__entire-title {
        z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
        font-weight: normal;
        color: #222;
        display: flex;
        align-items: center;
        min-height: 0.855rem;
        justify-content: space-between;
        .font-dpr(20px);
        line-height: .32rem;
        .txt-l();

        .title-icon {
            .fr();
            color: #999;
            font-size: 12px;
        }

        width: 100%;
        margin-top: 0;
        padding: 0 0 0 0;
        background: #fff;
        a {
            display: flex;
            justify-content: space-between;
            flex: 1;
            overflow: hidden;
        }
        .title-icon {
            transition: .3s all ease;
            &.expand {
                transform: rotateZ(180deg);
            }
        }
      }
      &__link-font {
        font-size: 0.32rem;
        display: flex;
        color: #222;
        line-height: 0.855rem;
        transition: .3s all cubic-bezier(0.2, 0.63, 0.57, 0.94);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &__link-count {
          margin-left: 0.053rem;
          font-size: 0.267rem;
          zoom: .95;
          color: #959595;
      }
      &__link-name {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &__link-icon {
        display: flex;
        align-items: center;
        .title-banner {
            height: 1.093rem;
            width: 1.093rem;
            margin: 0.08rem 0.32rem;
            transition: .3s opacity cubic-bezier(0.2, 0.63, 0.57, 0.94);
        }
      }
      &__entire-title--first-line {
          margin-top: 0;
      }
    }

    &.sidebar-pop {
      .c-cate {
        &__entire-title {
          // height: unset;
          // line-height: unset;
          font-size: 0.267rem;

        }
      }
    }
  }
}
</style>
