var render = function render(){var _vm=this,_c=_vm._self._c;return _c('s-tab',{attrs:{"type":'auto'},on:{"change":_vm.handleTabChange},model:{value:(_vm.selectTab),callback:function ($$v) {_vm.selectTab=$$v},expression:"selectTab"}},_vm._l((_vm.tabData),function(item,key,index){return _c('s-tab-item',{directives:[{name:"expose",rawName:"v-expose",value:({ 
      code: 'categoryTopCate',
      id: '1-14-1-18', 
      prefix: _vm.pageCode,
      delayReady: 1500,
      data: {
        item,
        index,
        crowdId: _vm.categoryCrowdInfo.crowdId,
      } 
    }),expression:"{ \n      code: 'categoryTopCate',\n      id: '1-14-1-18', \n      prefix: pageCode,\n      delayReady: 1500,\n      data: {\n        item,\n        index,\n        crowdId: categoryCrowdInfo.crowdId,\n      } \n    }"}],key:key,staticClass:"j-c-content__tab-item",class:[`j-c-content__tab-item-${item.channel_type}`, _vm.selectTab == item.channel_type ? 'j-sa-active-topcategory' : ''],attrs:{"id":item.channel_type,"data-index":index,"data-name":item.channelName || item.name,"data-channel-type":item.channel_type,"data-auto-map":item.autoMap,"data-top-category":`${index + 1}\`${item.channelName || item.name}\`${item.channel_type}\`${_vm.categoryCrowdInfo.crowdId || 0}`,"aria-label":item.name,"role":"button","tabindex":"0"}},[_vm._v("\n    "+_vm._s(item.name)+"\n  ")])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }