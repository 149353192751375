<template>
  <div class="c-cate__entire-banner1">
    <BaseImg
      v-for="(imageItem, itemIndex) in cate.props.items"
      :key="itemIndex" 
      ref="baseImageRef"
      v-tap="getAnalysisData('1-14-1-23', {item: imageItem, index: compIndex, channel: { ...activeChannel?.channel, index: activeChannel.channelIndex}, oneCateContent: activeLeftContent, oneCate: activeChannel?.oneCate, cateType })"
      v-expose="getAnalysisData('1-14-1-22', {delayReady: 300, item: imageItem, index: compIndex, channel: { ...activeChannel?.channel, index: activeChannel.channelIndex}, oneCateContent: activeLeftContent, oneCate: activeChannel?.oneCate, cateType })" 
      fit="contain"
      class="j-cate-two"
      :placeholder="{
        width: 332,
        height: 314,
      }"
      :custom-ratio="getCustomRatio(imageItem)"
      :ratio="imageItem.image.ratio"
      :img-src="imageItem.image.src"
      :img-design-width="TWO_IMAGE_DESIGN_WIDTH"
      :img-data-exp="{}"
      :ada="imageItem.ada"
      :alt="imageItem.hrefTitle"
      :observer-image="false"
      @click="(event) => clickItem(event, imageItem)"
    />
  </div>
</template>
<script setup>
import { defineProps } from 'vue'
import { TWO_IMAGE_DESIGN_WIDTH } from 'public/src/pages/product_app/store/modules/category/const.js'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { mergeQueryString } from '@shein/common-function'
import { getGenerateLLinkParams } from '../utils'
import Monitor from 'public/src/pages/common/home_common/monitor.js'

import useAnalysis from '../hooks/useAnalysis'
import useCccLink from '../hooks/useCccLink'
import useRouter from '../hooks/useRouter'
const { getAnalysisData } = useAnalysis()
const { router } = useRouter() 
const { cccLink, jumpLink } = useCccLink({
  sceneData: {
    sceneName: props.cateType
  },
  propData: props.cate || {},
})

const props = defineProps({
  cate: {
    type: Object,
    default: () => {}
  },
  activeChannel: {
    type: Object,
    default: () => ({})
  },
  activeLeftContent: {
    type: Object,
    default: () => ({})
  },
  cateType: {
    type: String,
    default: ''
  },
  handleClose: {
    type: Function,
    default: () => {}
  },
  compIndex: {
    type: Number,
    default: 0
  }
})
const getCustomRatio = (item) => {
  const { width, height } = item.image
  return `${(height * 100 / width)}%`
}
const clickItem = (event, imageItem) => {
  let url = cccLink.getLink({
    item: imageItem?.categoryType, 
    cateLinks: {},
    compIndex: props.compIndex, 
    index: -1,
  })
  // 拼接跳转埋点参数到url 上
  url = mergeQueryString({
    mergeObj: getGenerateLLinkParams({ 
      cate: imageItem,
      cateType: props.cateType, 
      channel: props.activeChannel?.channel,
      channelIndex: props.activeChannel?.channelIndex,
      oneCateContent: props.activeLeftContent,
      compIndex: props.compIndex,
      oneCate: props.activeChannel?.oneCate,
    }),
    url
  })
  Monitor.metricCategoryClick({
    tags: { 
      page: 'page_category',
      cate_level: '2',
    },
    message: 'Right level second category click'
  })
  if (imageItem.categoryType.hrefType != 'noJump') {
    props?.handleClose && props?.handleClose()
  }
  jumpLink({ url, hrefType: imageItem?.categoryType?.hrefType, router })
}
</script>
  
<style lang="less" scoped>
  /* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
  .c-new-category {
    .c-cate__entire-banner1 {
      display: flex;
      width: 100%;
      .cate-banner-box {
        display: flex;
        align-items: center;
      }
      .base-img {
        margin-top: 0.32rem;
        overflow: hidden;
      }
      img {
        width: 100%;
      }
    }
  }
  </style>
  
