<template>
  <div
    ref="cate-slider"
    class="cate-slider"
    :style="{marginTop: index !== 1 ? '.32rem' : ''}"
  >
    <div
      class="cate-slider__container"
    >
      <swiper-container 
        ref="swiper"
        class="swiper-wrapper cate-slider__wrapper"
        init="false"
        destroy-on-disconnected="false"
      >
        <swiper-slide
          v-for="(item) in imgList"
          ref="cate-slider__slider"
          :key="item.position"
          class="swiper-slide cate-slider__slider j-cate-two-slider"
          :style="{minWidth: item.realWidth, minHeight: item.realHeight}"
          :title="item.locationInfo | joinShowUrl"
          :data-report-tag="active.channel + '-' + active.oneCate + '-' + item.position + '-' + cateType"
          :data-position="item.position"
          :data-sa-info="item.analysisInfo.saInfo"
          :data-ga-info="item.analysisInfo.gaInfo"
          :data-href-type="item.locationInfo.hrefType"
          :data-jump-type="item.locationInfo.router"
          :data-href-query="item.locationInfo.query"
          :data-href-url="item.locationInfo.url"
          :data-href-target="item.hrefTarget"
          :data-map-id="item.navNodeId"
          :data-img-id="item.imgId"
          :data-sa-ps="generateSaPs({ position: item.position })"
          :data-sacontent="item.analysisInfo.saContent"
          @click="twoCategorySelectedHandler({cate: item, cateType, event: $event})"
        >
          <a
            class="cate-slider__img cate-swiper-img"
            :href="item.locationInfo | joinShowUrl"
            aria-hidden="true"
            tabindex="0"
          >
            <div
              class="cate-slider__slide-cover"
              style="transform: translate3d(0,0,0)"
            >
              <img
                :style="{minWidth: item.realWidth, minHeight: item.realHeight}"
                :data-parent-index="active.oneCate"
                :class="{ lazyload: !item._preloadUrl }"
                :data-src="!item._preloadUrl && item._target"
                :data-design-width="!item._preloadUrl && SLIDER_LOGIN_DESIGN_WIDTH"
                :src="item._preloadUrl"
              />
            </div>
          </a>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</template>


<script>
import cateItemMixin from '../mixin/ccc-cate-item-mixin'
import { SLIDER_LOGIN_DESIGN_WIDTH } from 'public/src/pages/product_app/store/modules/category/const.js'
import { register } from 'swiper/element'
import { Pagination, Autoplay } from 'swiper/modules'
typeof window !== 'undefined' && register()
const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}


export default {
  mixins: [cateItemMixin],
  props: {
    cate: {
      type: Object,
      default() {
        return {}
      }
    },
    active: {
      type: Object,
      default() {
        return {
          channel: '2',
          oneCate: 0
        }
      }
    },
    cateType: {
      type: String,
      default: 'cat'
    },
    contentWidth: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      cateSwiper: null,
      activeIndex: '',
      isSwiperInit: false,
      SLIDER_LOGIN_DESIGN_WIDTH
    }
  },
  computed: {
    imgList () {
      let temp = this.cate?.thumb || []
      if (this.carouselWay === 'optimization') {
        let totalWeights = temp.reduce((acc, cur) => acc + Number(cur.weights || 0), 0)
        temp.forEach(i => {
          i.weights = i.weights / totalWeights + Math.random()
        })
        temp.sort((a, b) => b.weights - a.weights )
      } else if (this.carouselWay === 'random') {
        temp.sort(() => Math.random - 0.5)
      }
      return temp
    },
    carouselWay () {
      return this.cate?.style?.carouselWay || 'order'
    }
  },
  mounted() {
    this.initSwiper()
  },
  methods: {
    initSwiper() {
      if (typeof window === 'undefined') return
      const swiperEl = this.$refs.swiper
      var self = this
      if (!swiperEl) return
      const swiperParams = {
        modules: [Pagination, Autoplay],
        injectStylesUrls: [ PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'],
        injectStyles: [
          `
          .swiper-pagination{
            position: absolute;
          }
          .swiper-pagination .swiper-pagination-bullet-active{
            background: rgba(34,34,34,.8);
            border: 1px solid hsla(0,0%,100%,.8);
          }
          .swiper-pagination .swiper-pagination-bullet{
            width: 6px;
            height: 6px;
          }
          ${this.swiperInjectStyle}
        `,
        ],
        loop: true,
        pagination: {
          hideOnClick: false,
        },
        spaceBetween: 0,
        speed: 300,
        slidesPerView: 1,
        slidesPerGroup: 1,
        allowTouchMove: true,
        autoplay: true,
        enabled: true,
        on: {
          slideChangeTransitionEnd: function() {
            self.$nextTick(() => {
              self.$emit('updateContent') // 更新内容，通知外部去更新埋点
            })
          },
        }
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      if (window.requestIdleCallback) {
        requestIdleCallback(() => {
          if (!swiperEl.initialized) {
            swiperEl.initialize()
          }
        })
      } else {
        setTimeout(() => {
          if (!swiperEl.initialized) {
            swiperEl.initialize()
          }
        }, 10)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cate-slider__container {
  margin-top: .32rem;
    width: 100%;
    overflow: hidden;
    margin-bottom: -1px;
}
.cate-slider__wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.cate-slider {
    &__wrapper {
        display: flex;
        flex-wrap: nowrap;
    }
    &__slide-cover.cate-slider__slide-cover {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          height: auto;
        }
    }
}
</style>
