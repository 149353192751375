var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-cate__entire-banner"},_vm._l((_vm.cate.thumb),function(threeCate,threeIndex){return _c('div',{key:threeIndex,staticClass:"j-cate-two cate-banner-box",style:({
      minWidth: threeCate.realWidth,
      minHeight: threeCate.realHeight,
    }),attrs:{"title":_vm._f("joinShowUrl")(threeCate.locationInfo),"data-report-tag":_vm.active.channel +
        '-' +
        _vm.active.oneCate +
        '-' +
        threeCate.position +
        '-' +
        _vm.cateType,"data-position":threeCate.position,"data-sa-info":threeCate.analysisInfo.saInfo,"data-ga-info":threeCate.analysisInfo.gaInfo,"data-href-type":threeCate.locationInfo.hrefType,"data-jump-type":threeCate.locationInfo.router,"data-href-query":threeCate.locationInfo.query,"data-href-target":threeCate.hrefTarget,"data-href-url":threeCate.locationInfo.url,"data-map-id":threeCate.navNodeId,"data-img-id":threeCate.imgId,"data-sa-ps":_vm.generateSaPs({ position: threeCate.position }),"data-sacontent":threeCate.analysisInfo.saContent},on:{"click":function($event){return _vm.twoCategorySelectedHandler({ cate: threeCate, cateType: _vm.cateType, event: $event })}}},[_c('a',{style:({
        minWidth: threeCate.realWidth,
        minHeight: threeCate.realHeight,
      }),attrs:{"href":_vm._f("joinShowUrl")(threeCate.locationInfo),"target":threeCate.hrefTarget,"role":"button","tabindex":"0","aria-label":threeCate.hrefTarget},on:{"click":_vm.aTagpreventDefault}},[_c('div',{staticClass:"cate-banner-box__img",style:({
          minWidth: threeCate.realWidth,
          minHeight: threeCate.realHeight,
        })},[_c('img',{class:{ lazyload: !threeCate._preloadUrl },style:({
            minWidth: threeCate.realWidth,
            minHeight: threeCate.realHeight,
          }),attrs:{"data-parent-index":_vm.active.oneCate,"data-src":!threeCate._preloadUrl ? threeCate._target : null,"data-design-width":!threeCate._preloadUrl ? _vm.BANNER_DESIGN_WIDTH : null,"src":threeCate._preloadUrl}})])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }