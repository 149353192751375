<script lang="jsx">
import {
  CLSSIFY_DESIGN_WIDTH,
  CLSSIFY_MARK_DESIGN_WIDTH,
} from 'public/src/pages/product_app/store/modules/category/const.js'
import {
  transformImg,
  replaceHrefSpecChar,
  mergeQueryString,
  parseQueryString,
} from '@shein/common-function'
import { getGenerateSaPs, handleJoinShowUrl, getIsExpandChild } from '../mixin/utils.js'
import { preloadGoodsRequest } from 'public/src/pre_requests/modules/productList/spaForHydration.js'

export default {
  name: 'CateClassify',
  functional: true,
  inject: {
    getStore: {
      from: 'cateClassifyStore',
      default: () => {
        return {
          categoryData: {}, 
          locals: {}, 
          onAnalysis: () => {}
        }
      },
    },
  },
  props: {
    cate: {
      type: Object,
      default() {
        return {}
      },
    },
    active: {
      type: Object,
      default() {
        return {
          channel: '2',
          oneCate: 0,
        }
      },
    },
    cateType: {
      type: String,
      default: 'cat',
    },
    contentWidth: {
      type: Number,
      default: 0,
    },
    activeParentCatePosition: {
      type: Number,
      default: 0,
    },
    activeChildCatePosition: {
      type: Number,
      default: 0,
    },
    isChildCate: {
      type: Boolean,
      defualt: false,
    },
    className: {
      type: Object,
      default: () => ({})
    },
    styles: {
      type: Object,
      default: () => ({})
    }
  },

  render(_, { props, injections, listeners }) {
    const { cate, active, cateType, activeParentCatePosition, activeChildCatePosition, isChildCate, className, styles } = props
    const { getStore } = injections
    const { categoryData, locals, onAnalysis } = getStore()
    
    const beforeNavChange = (_href, localsSite) => {
      const cRegx = /.+-c-(\d+).+/
      let cat_id = cRegx.exec(_href)
      if (cat_id) {
        cat_id = cat_id[1]
      }

      if (
        [
          'ar',
          'ar-en',
          'in',
          'es',
          'us',
          'de',
          'uk',
          'fr',
          'tw',
          'en',
          'hk',
          'se',
          'sesv',
          'vn',
        ].includes(localsSite)
      ) {
        return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '') })
      } else if (
        [
          'cl',
          'arg',
          'ru',
          'il',
          'ilen',
          'th',
          'then',
          'ca',
          'cafr',
          'eur',
          'eurfr',
          'eurde',
          'eurnl',
          'eurpt-pt',
        ].includes(localsSite)
      ) {
        let siteFix = localsSite
        if (['th', 'then'].includes(localsSite)) siteFix = 'th'
        if (['il', 'ilen'].includes(localsSite)) siteFix = 'il'
        if (['ca', 'cafr'].includes(localsSite)) siteFix = 'ca'
        if (
          ['eur', 'eurfr', 'eurde', 'eurnl', 'eurpt-pt'].includes(localsSite)
        )
          siteFix = 'eur'
        // 若链接中已含有站点，则不再添加站点

        const isSiteInclude = (url, site) => {
          const reg = new RegExp(`/${site}/`)
          return reg.test(url)
        }

        if (isSiteInclude(_href, siteFix)) {
          return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '') })
        } else {
          return replaceHrefSpecChar({
            href: _href.replace(/^\/pwa/, '/' + siteFix),
          })
        }
      }
      return replaceHrefSpecChar({ href: _href })
    }

    const getAddUrlParams = ({ url = '', pos = '', id = '' }, localsSite) => {
      const params = { pageFrom: pos }
      if (id) {
        Object.assign(params, {
          adp: id,
        })
      }
      url = mergeQueryString({
        mergeObj: params,
        url,
      })
      url = beforeNavChange(url, localsSite)
      url = url && url.replace('%', '')
      return url
    }

    const generateProductSource = ({ cate = {}, cateType = '', currentChannel, oneCate }) => {
      const gaInfoArr = (cate?.analysisInfo?.gaInfo || '-`').split('-\`')
      const oneCateInfo = currentChannel?.child[oneCate] || {}
      const isWebLink = cate?.analysisInfo?.webLink
      return `${cateType}-${gaInfoArr[0]}-${
        currentChannel?.channelName || currentChannel?.name
      }-${oneCateInfo?.enName || oneCateInfo?.name}-${
        isWebLink ? encodeURIComponent(gaInfoArr[1]) : gaInfoArr[1]
      }`
    }

    const genreateCateUrl = ({ cate = {}, cateType, event }, { isClick }) => {
      const type = cate?.style?.type
      if (type === 'TEXT_EXTENSIBLE' || type == 'IMAGE_TEXT_EXTENSIBLE') return // 可展开的分类不跳转 
  
      const { jumpType, query, userpath, userpathFrom } = cate.locationInfo
      let { url } = cate.locationInfo
      const currGoodsId = cate.goodsId
      const currentTarget = event.currentTarget

      if (!url) {
        return
      }

      // 只有品类图存在自动配图商品id
      const urlParams = currGoodsId ? { url, pos: cateType, id: currGoodsId } : { url, pos: cateType }
      const localsSite = locals.lang
      url = getAddUrlParams(urlParams, localsSite)

      const currentChannel = categoryData[active.channel] || {}
      const oneCate = active.oneCate

      const userpathquery = userpath
        ? `&srctype=category&userpath=${
            (cateType === 'cat' ? userpathFrom.cate : userpathFrom.shop) +
            userpath
          }`
        : ''
      const params = Object.assign(
        {},
        parseQueryString(query + userpathquery),
        { src_module: cateType },
        {
          src_identifier: `fc=${currentChannel.channelName}\`sc=${
            document.querySelector('.j-sa-active-one')?.getAttribute('data-name') || 0
          }\`tc=0\`oc=${currentTarget.dataset.name || 0}\`ps=${
            currentTarget.dataset.saPs || 0
          }\`jc=${cate.analysisInfo.saContent || 0}`,
        },
        { src_tab_page_id: window.getSaPageInfo?.tab_page_id || '' },
        {
          productsource: generateProductSource({ cate, cateType, currentChannel, oneCate }),
        },
        cate.mallCodes ? { mallCode: cate.mallCodes } : {},
        { fromPageType: 'category' },
      )

      let jumpUrl = mergeQueryString({ mergeObj: params, url })
      if (jumpUrl == decodeURI(location.pathname + location.search)) { // 如果是相同路由 关闭弹窗
        if (isClick) {
          listeners.twoCategorySelectedHandler?.()
          window.setTimeout(() => window.scrollTo(0, 0))  // 同地址不会触发vue router任何钩子, 得手动置顶
        }
        return
      }

      jumpUrl = replaceHrefSpecChar({ href: jumpUrl })
      if (jumpType === 'redirect') { // 重定向
        if (isClick) {
          location.href = jumpUrl
        }
        return
      } 

      if (jumpType === 'router') { // spa内部跳转 重置列表组件状态
        if (isClick){
          listeners.changeRouter && listeners.changeRouter(jumpUrl)
          listeners.twoCategorySelectedHandler?.()
          if (!listeners.changeRouter) { // 防止没有changeRouter方法时，跳转失败
            location.href = jumpUrl
          }
        }
        return jumpUrl
      }
    }

    const handlePointerover = ({ cate = {}, cateType, event }) => {
      let path = genreateCateUrl({ cate, cateType, event }, { isClick: false })
      if (!path) return
      preloadGoodsRequest.triggerNotice(path)
    }

    const onClickItem = async ({ cate = {}, cateType, event }) => {
      const path = genreateCateUrl({ cate, cateType, event }, { isClick: true })
      preloadGoodsRequest.click(path)
      event && onAnalysis({ target: event.currentTarget }) // 埋点上报  
    }

    return (
      <ul class={ ['c-cate__classify-wrap', className] } style={ styles } >
        {cate.thumb.map(threeCate => {
          const isExpandChild = getIsExpandChild(cate)
          return (
            <li
              class={[
                { 'j-expand-title-child': isExpandChild },
                'j-cate-two cate-classify__item',
              ]}
              title={handleJoinShowUrl(threeCate.locationInfo)}
              data-report-tag={
                active.channel +
                '-' +
                active.oneCate +
                '-' +
                threeCate.position +
                '-' +
                cateType
              }
              data-position={threeCate.position}
              data-sa-info={threeCate.analysisInfo.saInfo}
              data-ga-info={threeCate.analysisInfo.gaInfo}
              data-href-type={threeCate.locationInfo.hrefType}
              data-jump-type={threeCate.locationInfo.router}
              data-href-query={threeCate.locationInfo.query}
              data-href-url={threeCate.locationInfo.url}
              data-name={threeCate.alt}
              data-map-id={threeCate.navNodeId}
              data-img-id={threeCate.imgId}
              data-isAuto={threeCate.isAuto}
              data-recommend-cate={threeCate.recommendCate}
              data-href-target={threeCate.hrefTarget}
              data-goodsId={threeCate.goodsId}
              data-sa-ps={getGenerateSaPs({
                position: threeCate.position,
                active: active,
                activeParentCatePosition: activeParentCatePosition,
                activeChildCatePosition: activeChildCatePosition,
                isChildCate: isChildCate,
              })}
              data-sacontent={threeCate.analysisInfo.saContent}
              data-category-id={threeCate.cateTreeNodeId}
              vOn:click={event => {
                onClickItem({
                  cate: threeCate,
                  cateType,
                  event,
                })
              }}
              vOn:pointerover={ (event) => {
                handlePointerover({
                  cate: threeCate,
                  cateType,
                  event,
                })
              }}
            >
              <a
                class='cate-classify__wrap'
                href={handleJoinShowUrl(threeCate.locationInfo)}
                tabindex='0'
                role='button'
                aria-label={threeCate.alt}
                vOn:click={event => {
                  event?.preventDefault()
                }}
              >
                <div
                  class={ ['cate-classify__img', { lazyload: !threeCate._preloadUrl, 'border': threeCate.recommendCate }] }
                  data-background-image={ !threeCate._preloadUrl ? threeCate._target : null }
                  data-design-width={ !threeCate._preloadUrl ? CLSSIFY_DESIGN_WIDTH : null }
                  style={ threeCate._preloadUrl ? { backgroundImage: `url(${threeCate._preloadUrl})` } : null }
                >
                  {threeCate.cornerMarkImgSrc ? (
                    <em
                      class='cate-classify__corner-mark lazyload'
                      data-background-image={transformImg({
                        img: threeCate.cornerMarkImgSrc,
                      })}
                      data-design-width={CLSSIFY_MARK_DESIGN_WIDTH}
                    ></em>
                  ) : null}
                </div>
                <div class='cate-classify__name'>{threeCate.alt}</div>
              </a>
            </li>
          )
        })}
      </ul>
    )
  },
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-new-category {
  .c-cate__classify-wrap {
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0 0 0;
    margin: 0 -0.1067rem;
    background: #fff;

    .cate-classify {
      &__item {
        width: 2.05rem;
        padding: 0 0 0 0;
        margin: 0 0.1067rem 0.4rem;
      }

      &__wrap {
        text-decoration: none;
        display: block;
      }

      &__img {
        width: 1.76rem;
        height: 1.76rem;
        margin: 0 auto;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkAQMAAABKLAcXAAAAA1BMVEX19fWwaZ+KAAAAE0lEQVQ4y2MYBaNgFIyCUUBXAAAFeAABxVDVdQAAAABJRU5ErkJggg==');
        border-radius: 50%;
        background-color: #f3f3f3;
        &.border {
          border: 0.5px solid #E4E4E4;
        }
      }
      &__corner-mark {
        position: absolute;
        .right(0);
        top: 0;
        width: 0.53rem;
        height: 0.53rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }

      &__name {
        line-height: 0.38rem;
        margin-top: 0.0533rem;
        text-align: center;
        font-weight: 400;
        font-size: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        height: unset;
        width: 2.08rem;
        font-size: 0.32rem;
        text-align: center;
        color: rgba(0, 0, 0, .6);
      }
    }
  }
}
</style>
