import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { stringifyQueryString, template } from '@shein/common-function'

// compontent
import tabHeader from '../components/header.vue'
import cateClassify from '../components/cate-classify.vue'
import cateTitle from '../components/cate-title.vue'
import cateBanner from '../components/cate-banner.vue'
import cateTwoImage from '../components/cate-two-image.vue'
import cateSlider from '../components/cate-slider.vue'

import service from 'public/src/pages/product_app/store/modules/category/service'
import { getDefaultExpandFirstLevel } from 'public/src/pages/product_app/store/modules/category/utils.js'
import Monitor from 'public/src/pages/common/home_common/monitor.js'

// 预知的SSR范围
const SSR_LAYOUT_HEIGHT = 600
// 二级类目高度
const CATE_CLASSIFY_HEIGHT = 80
// 二级类目标题高度
const CATE_TITLE_HEIGHT = 30

export default {
  components: {
    tabHeader,
    cateClassify,
    cateTitle,
    cateBanner,
    cateTwoImage,
    cateSlider
  },
  data () {
    return {
      oneCategoryClicked: false,
      queueNum: 0,
      currentNextPage: false,
    }
  },
  computed: {
    ...mapState('category', ['language', 'cat', 'sidecat', 'categoryCrowdInfo', 'isAllTabAutoMap', 'selectedOneCate', 'categoryData', 'categoryDataListByOrder']),
    ...mapGetters('category', ['getterAbtInfo']),
    cateLinks() {
      return this.categoryData?.[this.active.channel]?.cateLinks || {}
    },
    style() {
      return this.categoryData?.[this.active.channel]?.style?.navStyleInfoList || []
    },
    abtInfo () {
      return this.getterAbtInfo({ channel: this.active.channel }) || {}
    },
    isOneCate() {
      return Object.keys(this.categoryDataListByOrder).length === 1
    },
    currentData() {
      return this[this.cateType]
    },
    active() {
      return this.currentData.active
    },
    channelKey() {
      return this.currentData.channelKey
    },
    oneCategoryKey() {
      return this.currentData.oneCategoryKey
    },
    storageType() {
      return this.currentData.storageType
    },
    oneCategory() {
      return this.currentData.oneCategory
    },
    twoCategory() {
      const isNew = !!this.categoryData[this.active.channel]?.isNew
      if (!isNew) return this.currentData.twoCategory
      return this.handleTwoCategory(this.currentData.twoCategory)
    },
    oneCategoryExpand() {
      return this.currentData.oneCategoryExpand
    },
    initCateData() {
      return this.currentData.initCateData
    },
    activeParentCatePosition() {
      let id = this.oneCategory?.[this.active.oneCate]?.titleParentId || this.oneCategory?.[this.active.oneCate]?.id || 0
      return this.generateOneCateIndex(id)
    },
    activeChildCatePosition() {
      let id = this.oneCategory[this.active.oneCate].titleParentId || this.oneCategory[this.active.oneCate].id
      return this.oneCategoryExpand[id]?.findIndex(item => item.id == this.oneCategory[this.active.oneCate].id) || 0
    },
    isChildCate() {
      return !!(this.oneCategory[this.active.oneCate].titleParentId)
    },
    // twoCategoryInitDone() {
    //   return this.currentData.twoCategoryInitDone
    // }
  },
  watch: {
    active: {
      handler (value, oldValue) {
        const initCategoryPage = !oldValue?.channel
        if (initCategoryPage) return // 初始化状态不需要处理
        const isChangeChannel = value.channel != oldValue.channel
        // 判断是否只是类型变化
        const isCateChange = (value.oneCate !== oldValue.oneCate) && !isChangeChannel
        this.handleActive({ isChangeChannel, isCateChange })
      }
    }
  },
  methods: {
    ...mapMutations('category', ['assignState', 'assignStateByCateType', 'assignStateSelectedOneCate']),
    ...mapActions('category', ['checkChannelLoadedData', 'checkOneCategoryLoadedData', 'setOneCategory', 'setTwoCategory']),

    handleActive({ isChangeChannel, isCateChange }) {
      if (!this.initCateData) return 
      this.setOneCategory({ cateType: this.cateType })
      if (!isCateChange) {
        this.setTwoCategory({ cateType: this.cateType })
      }
      
      this.$nextTick(() => {
        this.handleActiveDom({ isChangeChannel })
        this.handleNextOneCateName()
        this.getCccStyleInfo &&  this.getCccStyleInfo()
      })
    },

    handleNextOneCateName() {
      this.nextOneCateName = this.oneCategory && this.oneCategory[this.active.oneCate + 1] && this.oneCategory[this.active.oneCate + 1].name || ''
    },

    handleActiveDom({ isChangeChannel }) {
      if (typeof window !== 'undefined') {
        if (this.refreshLayout) {  // 分类侧边导航
          this.refreshLayout({ scrollTop: true, isChangeChannel })
        } else { // 分类页
          this.handleLayout({ scrollTop: true, isChangeChannel, isChangeOneCate: !isChangeChannel, nextPage: this.currentNextPage }) // 重新计算滚动条位置
          this.currentNextPage = false
        }
      }
    },

    async channelSelectedHandler ({ item, target = null, isManual = false } = {}) {
      this.analysis && this.analysis.refreshAnalysisInfoByChangeChannel()

      this.queueNum += 1
      
      await this.checkChannelLoadedData({
        channel: item.channel_type,
        cateType: this.cateType,
      }).catch(() =>{})

      this.assignStateSelectedOneCate({ [this.active.channel]: this.active.oneCate })

      let preOneCate = this.selectedOneCate[item.channel_type] || 0
      let oneCate = (item.child[preOneCate]?.isFatherFirstTitle && (!item.child[preOneCate].child || !item.child[preOneCate].child.length)) ? Number(preOneCate) + 1 : preOneCate

      await this.checkOneCategoryLoadedData({
        channel: item.channel_type,
        oneCate,
        cateType: this.cateType
      }).catch(() =>{})

      this.queueNum -= 1
      if (this.queueNum > 0) return // 处理多个请求时，只处理最后一个请求

      this.assignStateByCateType({
        cateType: this.cateType,
        active: {
          channel: item.channel_type,
          channelIndex: item.index,
          oneCate,
          isNew: item.isNew
        }
      })

      if (this.channelKey) {
        service.cacheSelectedIndex({
          channel: this.active.channel,
          oneCate: this.active.oneCate,
          channelKey: this.channelKey,
          oneCategoryKey: this.oneCategoryKey,
          storageType: this.storageType
        })
      }

      if (this.cateType == 'cat') {
        let newQuery = Object.assign({}, this.$route.query, {
          channelId: this.active.channel,
          oneCate
        })
        this.$router.replace(`${this.$route.path}?${stringifyQueryString({ queryObj: newQuery })}`)

        const { expandRearFirstLevel, expandFirstLevelId } = getDefaultExpandFirstLevel({ 
          oneCategory: item.child, 
          // allTabAbt: this.allTabAbt,
          oneCate: this.active.oneCate,
        })
        this.assignState({ expandRearFirstLevel, expandFirstLevelId })
      }

      if (!isManual) {
        // click
        this.$nextTick(() => {
          requestIdleCallback(() => {
            this.analysis && this.analysis.channelClickHandle({ cate: item, target })
          })
        })
      }
    },
    async oneCategorySelectedHandler ({ oneCategoryIndex, clickFirstLevel = false, nextPage = false } = {}) {
      this.currentNextPage = nextPage
      if (this.oneCategoryClicked) return // 防止重复点击
      this.oneCategoryClicked = true
      try {
        Monitor.metricCategoryClick({
          tags: { 
            page: 'page_category',
            cate_level: '1',
          },
          message: 'Left level first category click'
        })
        this.analysis && this.analysis.refreshAnalysisInfoByChangeOneCate()

        let thisOneCate = this.oneCategory[oneCategoryIndex]

        let isFatherFirstTitle = thisOneCate.isFatherFirstTitle && (!thisOneCate.child || !thisOneCate.child.length)
        let targetOneCategoryIndex = oneCategoryIndex
        let isChange = true
        if (isFatherFirstTitle) {
          if (this.active.oneCate >= oneCategoryIndex &&
            this.active.oneCate <= (oneCategoryIndex + this.oneCategoryExpand[thisOneCate.id].length)) {
            targetOneCategoryIndex = this.active.oneCate
          } else {
            targetOneCategoryIndex += 1
          }
        }
        if (Number(targetOneCategoryIndex) == this.active.oneCate) {
          isChange = false
        }

        // const isChangeChannel = value.channel != oldValue.channel
        

        this.assignStateByCateType({
          cateType: this.cateType,
          active: {
            channel: this.active.channel,
            channelIndex: this.active.channelIndex,
            oneCate: Number(targetOneCategoryIndex),
            isNew: this.active.isNew
          }
        })

        await this.checkOneCategoryLoadedData({
          channel: this.active.channel,
          oneCate: Number(targetOneCategoryIndex),
          cateType: this.cateType
        })

        this.setTwoCategory({ cateType: this.cateType })

        if (this.oneCategoryKey) {
          service.cacheSelectedIndex({
            oneCate: Number(targetOneCategoryIndex),
            oneCategoryKey: this.oneCategoryKey,
            storageType: this.storageType
          })
        }

        if (this.cateType == 'cat') {
          let newQuery = Object.assign({}, this.$route.query, {
            channelId: this.active.channel,
            oneCate: Number(targetOneCategoryIndex)
          })
          this.$router.replace(`${this.$route.path}?${stringifyQueryString({ queryObj: newQuery })}`)

          if (clickFirstLevel) {
            const params = {
              expandRearFirstLevel: '' // 清空
            }
            if (this.expandRearFirstLevel != thisOneCate.id) {
              params.expandFirstLevelId = this.expandFirstLevelId != thisOneCate.id ? thisOneCate.id : undefined
            }
            this.assignState(params)
          } else if (nextPage) {
            this.assignState({
              expandFirstLevelId: this.oneCategory[targetOneCategoryIndex].titleParentId
            })
          }

          if (isFatherFirstTitle) {
            this.$nextTick(() => {
              this.analysis && this.analysis.oneCateClickHandle({
                nextPage,
                isFatherFirstTitle,
                expandFirstLevelId: this.expandFirstLevelId
              })
            })
          }

          if (isChange) {
            this.$nextTick(() => {
              this.analysis && this.analysis.oneCateClickHandle({
                nextPage
              })
              this.$refs.iScrollRight?.refresh?.(true)
            })
          }
        } else {
          this.$nextTick(() => {
            this.analysis && this.analysis.oneCateClickHandle({
              nextPage
            })
          })
        }
      } catch (error) {
        console.log(error)
      }

      this.oneCategoryClicked = false
    },

    generateOneCateIndex(id) {
      let onlyParents = this.oneCategory.filter(item => !item.titleParentId)
      return onlyParents.findIndex(item => item.id == id)
    },

    scrollStop (e) {
      if (!e) return
      e.preventDefault()
    },
    template,
    handleTwoCategory(twoCategory) {
      const curTime = new Date().getTime()
      if (this.cateType === 'cat') {
        twoCategory = this.addSsrFlagToCategory(twoCategory)
      }
      return twoCategory.filter(c => {
        const { styleType, props } = c
        if (styleType !== 'IMAGE_CAROUSEL_COMPONENT') return true
        let items = props?.items ?? []
        items = items.filter(n => {
          const { startTime = null, endTime = null } = n
          if (startTime && curTime < startTime) {
            return false
          }
          if (endTime && curTime > endTime) {
            return false
          }
          return true
        })
        if (items.length === 0) return false
        c.props.items = items
        return true
      })
    },
    // SSR时给twoCategory添加fsFlag首屏标识, 暂时不考虑图片导航
    addSsrFlagToCategory(twoCategory) {
      let compsHeight = 0
      return twoCategory.map(cate => {
        if (compsHeight > SSR_LAYOUT_HEIGHT) {
          cate.fsFlag = false
          return cate
        }
        const { child, styleType } = cate
        if (styleType !== 'FLOOR_COMPONENT') {
          cate.fsFlag = true
          return cate
        }
        const itemLength = child?.[0]?.props?.items?.length ?? 0

        cate.fsFlag = true
        compsHeight += CATE_TITLE_HEIGHT + Math.ceil(itemLength / 3) * CATE_CLASSIFY_HEIGHT
        return cate
      })
    }
  },
}
