import { parseQueryString } from '@shein/common-function'
import { getGenerateSaPs } from  'public/src/pages/category/mixin/utils.js'

const generateProductSource  = ({ cate = {}, cateType = '', channel, oneCateInfo }) => {
  // generate product source for analysis
  const gaInfoArr = (cate?.contentAnalysis?.analysisInfo?.gaInfo || '-`').split('-\`')
  const isWebLink = cate?.analysisInfo?.webLink
  return `${cateType}-${gaInfoArr[0]}-${channel?.channelName || channel?.name }-${oneCateInfo?.enName || oneCateInfo?.name || oneCateInfo?.firstFloorContent?.props?.metaData?.categoryName || ''}-${isWebLink ? encodeURIComponent(gaInfoArr[1]) : gaInfoArr[1]}`
}

export const getGenerateLLinkParams = ({ cate, cateType, channel, channelIndex, oneCateContent, compIndex, oneCate }) => {
  const { query, userpath, userpathFrom = {} } = cate?.contentAnalysis?.locationInfo || {}
  const item_loc = cate?.contentAnalysis?.item_loc || 0
  const userpathquery = userpath ? `&srctype=category&userpath=${(cateType === 'cat' ? userpathFrom.cate || '' : userpathFrom.shop || '') + userpath}` : ''
  const oneCateName = oneCateContent?.firstFloorContent?.props?.metaData?.categoryName
  const ps = getGenerateSaPs({ position: item_loc, active: { ...channel, channelIndex: channelIndex }, activeParentCatePosition: oneCate, activeChildCatePosition: compIndex, isChildCate: false })
  const params = Object.assign({},
    parseQueryString(query + userpathquery),
    { src_module: cateType },
    { src_identifier: (`fc=${channel.channelName || 0}\`sc=${oneCateName || 0}\`tc=0\`oc=${cate.categoryName || 0}\`ps=${ps || 0}\`jc=${cate.contentAnalysis?.analysisInfo.saContent || 0}`) },
    { src_tab_page_id: window.getSaPageInfo?.tab_page_id || '' },
    {
      productsource: generateProductSource({ cate, cateType, channel, oneCateInfo: oneCateContent.oneCate })
    },
    cate.mallCodes ? { mallCode: cate.mallCodes } : {}, 
    cate.hrefType || cate?.categoryType?.hrefType ? { fromPageType: 'category' } : {},
  )
  return params
}
