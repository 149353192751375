<template>
  <div class="c-cate__entire-banner">
    <div
      v-for="(line, lineIndex) in Math.ceil(cate.thumb.length / 2)"
      :key="lineIndex"
      class="cate-banner-box"
    >
      <!-- first img -->
      <div
        v-if="!!getTwoImgThumb({ cate, lineIndex })"
        class="j-cate-two"
        :style="{
          width: getWidthPercent({
            totalWidth: getTwoImgThumbWidth({ cate, lineIndex }),
            width: getTwoImgThumb({ cate, lineIndex }).width,
          }),
        }"
        :title="getTwoImgThumb({ cate, lineIndex }).locationInfo | joinShowUrl"
        :data-report-tag="
          active.channel +
            '-' +
            active.oneCate +
            '-' +
            getTwoImgThumb({ cate, lineIndex }).position +
            '-' +
            cateType
        "
        :data-position="getTwoImgThumb({ cate, lineIndex }).position"
        :data-sa-info="getTwoImgThumb({ cate, lineIndex }).analysisInfo.saInfo"
        :data-ga-info="getTwoImgThumb({ cate, lineIndex }).analysisInfo.gaInfo"
        :data-href-type="
          getTwoImgThumb({ cate, lineIndex }).locationInfo.hrefType
        "
        :data-jump-type="
          getTwoImgThumb({ cate, lineIndex }).locationInfo.router
        "
        :data-href-query="
          getTwoImgThumb({ cate, lineIndex }).locationInfo.query
        "
        :data-href-url="getTwoImgThumb({ cate, lineIndex }).locationInfo.url"
        :data-href-target="getTwoImgThumb({ cate, lineIndex }).hrefTarget"
        :data-img-id="getTwoImgThumb({ cate, lineIndex }).imgId"
        :data-map-id="cate.navNodeId"
        :data-sa-ps="
          generateSaPs({
            position: getTwoImgThumb({ cate, lineIndex }).position,
          })
        "
        :data-sacontent="
          getTwoImgThumb({ cate, lineIndex }).analysisInfo.saContent
        "
        @click="
          twoCategorySelectedHandler({
            cate: getTwoImgThumb({ cate, lineIndex }),
            cateType,
            event: $event,
          })
        "
      >
        <a
          :href="getTwoImgThumb({ cate, lineIndex }).locationInfo | joinShowUrl"
          aria-hidden="true"
          role="none"
          tabindex="-1"
          @click="aTagpreventDefault"
        >
          <img
            :class="{ lazyload: !getTwoImgThumb({ cate, lineIndex })._preloadUrl }"
            :data-src="getTwoImgThumb({ cate, lineIndex })._target"
            :data-design-width="TWO_IMAGE_DESIGN_WIDTH"
            :style="{
              minWidth: getTwoImgThumb({ cate, lineIndex }).realWidth,
              minHeight: getTwoImgThumb({ cate, lineIndex }).realHeight,
            }"
            :data-parent-index="active.oneCate"
            :src="getTwoImgThumb({ cate, lineIndex })._preloadUrl"
          />
        </a>
      </div>

      <!-- second img -->
      <div
        v-if="!!getTwoImgThumb({ cate, lineIndex, offset: 1 })"
        class="j-cate-two"
        :style="{
          width: getWidthPercent({
            totalWidth: getTwoImgThumbWidth({ cate, lineIndex }),
            width: getTwoImgThumb({ cate, lineIndex, offset: 1 }).width,
          }),
        }"
        :title="
          getTwoImgThumb({ cate, lineIndex, offset: 1 }).locationInfo
            | joinShowUrl
        "
        :data-report-tag="
          active.channel +
            '-' +
            active.oneCate +
            '-' +
            getTwoImgThumb({ cate, lineIndex, offset: 1 }).position +
            '-' +
            cateType
        "
        :data-position="getTwoImgThumb({ cate, lineIndex, offset: 1 }).position"
        :data-sa-info="
          getTwoImgThumb({ cate, lineIndex, offset: 1 }).analysisInfo.saInfo
        "
        :data-ga-info="
          getTwoImgThumb({ cate, lineIndex, offset: 1 }).analysisInfo.gaInfo
        "
        :data-href-type="
          getTwoImgThumb({ cate, lineIndex, offset: 1 }).locationInfo.hrefType
        "
        :data-jump-type="
          getTwoImgThumb({ cate, lineIndex, offset: 1 }).locationInfo.router
        "
        :data-href-query="
          getTwoImgThumb({ cate, lineIndex, offset: 1 }).locationInfo.query
        "
        :data-href-url="
          getTwoImgThumb({ cate, lineIndex, offset: 1 }).locationInfo.url
        "
        :data-img-id="getTwoImgThumb({ cate, lineIndex, offset: 1 }).imgId"
        :data-href-target="getTwoImgThumb({ cate, lineIndex }).hrefTarget"
        :data-map-id="cate.navNodeId"
        :data-sa-ps="
          generateSaPs({
            position: getTwoImgThumb({ cate, lineIndex, offset: 1 }).position,
          })
        "
        :data-sacontent="
          getTwoImgThumb({ cate, lineIndex, offset: 1 }).analysisInfo.saContent
        "
        @click="
          twoCategorySelectedHandler({
            cate: getTwoImgThumb({ cate, lineIndex, offset: 1 }),
            cateType,
            event: $event,
          })
        "
      >
        <a
          :href="
            getTwoImgThumb({ cate, lineIndex, offset: 1 }).locationInfo
              | joinShowUrl
          "
          aria-hidden="true"
          role="none"
          tabindex="-1"
          @click="aTagpreventDefault"
        >
          <img
            :style="{
              minWidth: getTwoImgThumb({ cate, lineIndex }).realWidth,
              minHeight: getTwoImgThumb({ cate, lineIndex }).realHeight,
            }"
            :class="{ lazyload: !getTwoImgThumb({ cate, lineIndex, offset: 1 })._preloadUrl }"
            :src="getTwoImgThumb({ cate, lineIndex, offset: 1 })._preloadUrl"
            :data-src="getTwoImgThumb({ cate, lineIndex, offset: 1 })._target"
            :data-design-width="TWO_IMAGE_DESIGN_WIDTH"
            :data-parent-index="active.oneCate"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import cateItemMixin from '../mixin/ccc-cate-item-mixin'
import { TWO_IMAGE_DESIGN_WIDTH } from 'public/src/pages/product_app/store/modules/category/const.js'

export default {
  mixins: [cateItemMixin],
  props: {
    cate: {
      type: Object,
      default() {
        return {}
      },
    },
    active: {
      type: Object,
      default() {
        return {
          channel: '2',
          oneCate: 0,
        }
      },
    },
    cateType: {
      type: String,
      default: 'cat',
    },
    contentWidth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      TWO_IMAGE_DESIGN_WIDTH
    }
  },
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-new-category {
  .c-cate__entire-banner {
    width: 100%;
    .cate-banner-box {
      display: flex;
      align-items: center;
      margin-top: 0.32rem;
    }

    img {
      width: 100%;
    }
  }
}
</style>
