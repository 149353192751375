var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Tab,{attrs:{"type":'auto'},on:{"change":_setup.handleTabChange},model:{value:(_setup.selectTab),callback:function ($$v) {_setup.selectTab=$$v},expression:"selectTab"}},_vm._l((_vm.tabData),function(item,index){return _c(_setup.TabItem,{directives:[{name:"expose",rawName:"v-expose",value:({ 
      code: 'categoryTopCate',
      id: '1-14-1-18', 
      prefix: 'pwaCategoryPage',
      delayReady: 1500,
      data: {
        item,
        index,
        crowdId: item.crowdId,
      } 
    }),expression:"{ \n      code: 'categoryTopCate',\n      id: '1-14-1-18', \n      prefix: 'pwaCategoryPage',\n      delayReady: 1500,\n      data: {\n        item,\n        index,\n        crowdId: item.crowdId,\n      } \n    }"},{name:"tap",rawName:"v-tap",value:({
      id: '1-14-1-19', 
      data: {
        item,
        index,
        crowdId: item.crowdId,
      } 
    }),expression:"{\n      id: '1-14-1-19', \n      data: {\n        item,\n        index,\n        crowdId: item.crowdId,\n      } \n    }"}],key:item.id,staticClass:"j-c-content__tab-item",class:[`j-c-content__tab-item-${item.id}`, _setup.selectTab == item.id ? 'j-sa-active-topcategory' : ''],attrs:{"id":item.id,"aria-label":item.name,"role":"button","tabindex":"0"}},[_vm._v("\n    "+_vm._s(item.name)+"\n  ")])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }