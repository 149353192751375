<template>
  <div class="c-cate__entire-banner1">
    <BaseImg
      v-for="(imageItem, itemIndex) in cate.props.items"
      :key="itemIndex"
      ref="baseImageRef"
      fit="contain"
      class="j-cate-two"
      :placeholder="{
        width: 332,
        height: 314,
      }"
      :custom-ratio="getCustomRatio(imageItem)"
      :ratio="imageItem.image.ratio"
      :img-src="imageItem.image.src"
      :img-design-width="TWO_IMAGE_DESIGN_WIDTH"
      :img-data-exp="{}"
      :ada="imageItem.ada"
      :alt="imageItem.hrefTitle"
      :observer-image="false"
      :data-report-tag="active.channel + '-' + active.oneCate + '-' + index + '-' + cateType + '-' + itemIndex"
      :data-sa-info="analysisInfo?.saInfo"
      :data-sacontent="`${imageItem.hrefType}_${imageItem.hrefTarget}`"
      :data-ga-info="analysisInfo?.gaInfo"
      :data-jump-type="locationInfo?.jumpType"
      :data-href-query="locationInfo?.query"
      :data-href-url="locationInfo?.url"
      :data-name="activeOneCategoryProps?.metaData?.categoryName || activeOneCategoryProps?.metaData?.categoryLanguage"
      :data-map-id="imageItem.id"
      :data-img-id="imageItem.image.md5"
      :data-href-target="imageItem?.categoryType?.hrefTarget"
      :data-href-type="imageItem?.categoryType?.hrefType"
      :data-category-id="cate.cateId"
      :data-sa-ps="generateSaPs({position: itemIndex})"
      @click.stop="(event) => clickItem(event, imageItem)"
    />
  </div>
</template>
  
<script>
import { mapState } from 'vuex'
import cateItemMixin from '../mixin/ccc-cate-item-mixin'
import { TWO_IMAGE_DESIGN_WIDTH } from 'public/src/pages/product_app/store/modules/category/const.js'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { mergeQueryString } from '@shein/common-function'

export default {
  name: 'CateNewImage',
  components: {
    BaseImg,
  },
  mixins: [cateItemMixin],
  props: {
    cate: {
      type: Object,
      default() {
        return {}
      },
    },
    cateLinks: {
      type: Object,
      default () {
        return {}
      }
    },
    active: {
      type: Object,
      default() {
        return {
          channel: '2',
          oneCate: 0,
        }
      },
    },
    cateType: {
      type: String,
      default: 'cat',
    },
    pos: {
      default: '',
      type: String
    },
    handleClose: {
      type: Function,
      default: () => {}
    },
    index: {
      default: 1,
      type: Number,
    }
  },
  data() {
    return {
      TWO_IMAGE_DESIGN_WIDTH
    }
  },
  computed: {
    ...mapState('category', ['cat']),
    cccLink() {
      return new CccLink({
        sceneData: {
          sceneName: this.cateType
        },
        propData: this.cate || {},
      })
    },
    analysisInfo() {
      return this.cate?.props?.analysisInfo ?? {}
    },
    locationInfo() {
      return this.cate?.props?.locationInfo ?? {}
    },
    activeOneCategoryProps() {
      const { active, oneCategory } = this.cat
      return oneCategory?.[active.oneCate]?.firstFloorContent?.props ?? {}
    }
  },
  methods: {
    clickItem(event, imageItem) {
      if (event) {
        const analysis = this.getAnalysisInstance()
        analysis?.twoCateClickHandle({ target: event.currentTarget?.parentNode })
      }
      const { oneCate = 0 } = this.active || {}
      const categoryType = imageItem?.categoryType ?? {}
      let url = this.cccLink.getLink({
        item: categoryType, 
        cateLinks: this.cateLinks,
        compIndex: oneCate, 
        index: -1,
      })
      // 拼接跳转埋点参数到url 上
      url = mergeQueryString({
        mergeObj: this.getGenerateLLinkParams({ cate: this.cate?.props?.metaData, cateType: this.cateType }),
        url
      })
      if (imageItem?.hrefType != 'noJump') {
        this.handleClose()
      }
      this.jumpLink({ url, hrefType: categoryType?.hrefType })
    },
    getCustomRatio(item) {
      const { width, height } = item.image
      return `${(height * 100 / width)}%`
    }
  }
}
</script>
  
<style lang="less" scoped>
  /* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
  .c-new-category {
    .c-cate__entire-banner1 {
      display: flex;
      width: 100%;
      .cate-banner-box {
        display: flex;
        align-items: center;
      }
      .base-img {
        margin-top: 0.32rem;
        overflow: hidden;
      }
      img {
        width: 100%;
      }
    }
  }
  </style>
  
