<template>
  <div class="c-cate__entire-banner">
    <div
      v-for="(threeCate, threeIndex) in cate.thumb"
      :key="threeIndex"
      class="j-cate-two cate-banner-box"
      :style="{
        minWidth: threeCate.realWidth,
        minHeight: threeCate.realHeight,
      }"
      :title="threeCate.locationInfo | joinShowUrl"
      :data-report-tag="
        active.channel +
          '-' +
          active.oneCate +
          '-' +
          threeCate.position +
          '-' +
          cateType
      "
      :data-position="threeCate.position"
      :data-sa-info="threeCate.analysisInfo.saInfo"
      :data-ga-info="threeCate.analysisInfo.gaInfo"
      :data-href-type="threeCate.locationInfo.hrefType"
      :data-jump-type="threeCate.locationInfo.router"
      :data-href-query="threeCate.locationInfo.query"
      :data-href-target="threeCate.hrefTarget"
      :data-href-url="threeCate.locationInfo.url"
      :data-map-id="threeCate.navNodeId"
      :data-img-id="threeCate.imgId"
      :data-sa-ps="generateSaPs({ position: threeCate.position })"
      :data-sacontent="threeCate.analysisInfo.saContent"
      @click="twoCategorySelectedHandler({ cate: threeCate, cateType, event: $event })"
    >
      <a
        :style="{
          minWidth: threeCate.realWidth,
          minHeight: threeCate.realHeight,
        }"
        :href="threeCate.locationInfo | joinShowUrl"
        :target="threeCate.hrefTarget"
        role="button"
        tabindex="0"
        :aria-label="threeCate.hrefTarget"
        @click="aTagpreventDefault"
      >
        <div
          :style="{
            minWidth: threeCate.realWidth,
            minHeight: threeCate.realHeight,
          }"
          class="cate-banner-box__img"
        >
          <img
            :style="{
              minWidth: threeCate.realWidth,
              minHeight: threeCate.realHeight,
            }"
            :data-parent-index="active.oneCate"
            :class="{ lazyload: !threeCate._preloadUrl }"
            :data-src="!threeCate._preloadUrl ? threeCate._target : null"
            :data-design-width="!threeCate._preloadUrl ? BANNER_DESIGN_WIDTH : null"
            :src="threeCate._preloadUrl"
          />
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import cateItemMixin from '../mixin/ccc-cate-item-mixin'

import { BANNER_DESIGN_WIDTH } from 'public/src/pages/product_app/store/modules/category/const.js' 

export default {
  mixins: [cateItemMixin],
  props: {
    cate: {
      type: Object,
      default() {
        return {}
      },
    },
    active: {
      type: Object,
      default() {
        return {
          channel: '2',
          oneCate: 0,
        }
      },
    },
    cateType: {
      type: String,
      default: 'cat',
    },
    contentWidth: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      BANNER_DESIGN_WIDTH
    }
  }
}
</script>

<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-new-category {
  .c-cate__entire-banner {
    width: 100%;
    .cate-banner-box {
      display: flex;
      align-items: center;

      &__img {
        position: relative;
        border: none;
      }
    }

    img {
      width: 100%;
    }
  }
}
</style>
