import {
  mergeQueryString,
  parseQueryString,
} from '@shein/common-function'

export const getGenerateSaPs = ({ position = 0, active, activeParentCatePosition, activeChildCatePosition, isChildCate } = {}) => {
  const channelIndex = Number(active.channelIndex) + 1
  const oneCateParentIndex = Number(activeParentCatePosition) + 1
  const oneCateChildIndex = Number(activeChildCatePosition) + 1
  const index = String(position).indexOf('_') > -1 ? position : Number(position) + 1

  return `tab${channelIndex < 10 ? `0${channelIndex}` : channelIndex}` +
    `navbar${oneCateParentIndex < 10 ? `0${oneCateParentIndex}` : oneCateParentIndex}` +
    `${isChildCate ? `_${oneCateChildIndex < 10 ? `0${oneCateChildIndex}` : oneCateChildIndex}` : ''}` +
    'menu01' +
    `dir${index}`
}

export const handleJoinShowUrl = (locationInfo) => {
  const { url, query } = locationInfo
  return (
    mergeQueryString({
      mergeObj: parseQueryString(query),
      url,
    }) || 'javascript:;'
  )
}

export const getIsExpandChild = cate => {
  const { parentCatNode } = cate
  const type = parentCatNode?.style || ''
  return type == 'TEXT_EXTENSIBLE' || type == 'IMAGE_TEXT_EXTENSIBLE'
}
