<template>
  <tab
    v-model="selectTab"
    :type="'auto'"
    @change="handleTabChange"
  >
    <tab-item
      v-for="(item, index) in tabData"
      :id="item.id"
      :key="item.id"
      v-expose="{ 
        code: 'categoryTopCate',
        id: '1-14-1-18', 
        prefix: 'pwaCategoryPage',
        delayReady: 1500,
        data: {
          item,
          index,
          crowdId: item.crowdId,
        } 
      }"
      v-tap="{
        id: '1-14-1-19', 
        data: {
          item,
          index,
          crowdId: item.crowdId,
        } 
      }"
      class="j-c-content__tab-item"
      :class="[`j-c-content__tab-item-${item.id}`, selectTab == item.id ? 'j-sa-active-topcategory' : '']"
      :aria-label="item.name"
      role="button"
      tabindex="0"
    >
      {{ item.name }}
    </tab-item>
  </tab>
</template>
<script setup>
import { Tab, TabItem } from '@shein/sui-mobile'
import { ref, defineProps, defineEmits, watch } from 'vue'
import Monitor from 'public/src/pages/common/home_common/monitor.js'
const props = defineProps({
  activeKey: {
    type: String || Number,
    default: ''
  },
  tabData: {
    type: Array,
    default () {
      return []
    }
  },
})
const emits = defineEmits(['selectedHandler'])

const selectTab = ref(props.activeKey)

watch(() => {
  return props.activeKey
}, (val) => {
  selectTab.value = val
})

const handleTabChange = (data) => {
  if (!data.oldVal) return // 首次进入页面不触发
  let item, itemIndex
  props.tabData.forEach((i, index) => {
    if (i.id == data.val) {
      item = i
      itemIndex = index
    }
  })
  Monitor.metricCategoryClick({
    tags: { 
      page: 'page_category',
      cate_level: '0',
    },
    message: 'category header tab click.'
  })
  emits('selectedHandler', { item, itemIndex, target: document.querySelector(`.j-c-content__tab-item-${data.val}`) })
}
</script>
<script>
export default {
  name: 'TabHeader',
}
</script>
