
<template>
  <ul class="c-cate__classify-wrap">
    <li
      v-for="(threeCate, threeIndex) in cate"
      :key="threeIndex + String(threeCate._id)"
      v-tap="getAnalysisData('1-14-1-23', {item: threeCate, index: compIndex, channel: { ...activeChannel?.channel, index: activeChannel.channelIndex}, oneCateContent: activeLeftContent, oneCate: activeChannel?.oneCate, cateType })" 
      v-expose="getAnalysisData('1-14-1-22', { delayReady: 300, item: threeCate, index: compIndex, channel: { ...activeChannel?.channel, index: activeChannel.channelIndex}, oneCateContent: activeLeftContent, oneCate: activeChannel?.oneCate, cateType })" 
      class="j-cate-two cate-classify__item"
      :class="{ 'j-expand-title-child': isExpandChild }"
      @click="clickItem({cate: threeCate, index: threeIndex, event: $event})"
      @pointerover="onPointerOver({cate: threeCate, index: threeIndex })"
    >
      <a
        class="cate-classify__wrap"
        tabindex="0"
        role="button"
        :aria-label="threeCate.categoryLanguage"
        @click="handleATagpreventDefault"
      >
       
        <div
          v-if="!fsFlag"
          :key="threeCate?.cover?.src"
          :class="['cate-classify__img', { lazyload: threeCate?.cover?.src, 'border': threeCate.autoRecommend }, 'fsp-element']"
          :data-background-image="cutImg(threeCate?.cover?.src)"
          :data-design-width="CLSSIFY_DESIGN_WIDTH"
          :style="threeCate._preloadUrl ? { backgroundImage: `url(${threeCate._preloadUrl})` } : {}"
        >
          <em
            v-if="threeCate.angle?.src"
            class="cate-classify__corner-mark lazyload"
            :data-background-image="cutImg(threeCate.angle?.src)"
            :data-design-width="CLSSIFY_MARK_DESIGN_WIDTH"
          ></em>
        </div>
 
        <div
          v-else
          :key="threeCate?.cover?.src + 'true'"
          :class="['cate-classify__img', { 'border': threeCate.autoRecommend }, 'fsp-element']"
          :data-design-width="CLSSIFY_DESIGN_WIDTH"
          :style="threeCate?.cover?.src ? { backgroundImage: `url(${cutImg(threeCate?.cover?.src)})` } : {}"
        >
          <em
            v-if="threeCate.angle?.src"
            class="cate-classify__corner-mark lazyload"
            :data-background-image="cutImg(threeCate.angle?.src)"
            :data-design-width="CLSSIFY_MARK_DESIGN_WIDTH"
          ></em>
        </div>
        <div 
          class="cate-classify__name"
          :class="{'zIndex': !isActive}"
        >{{ threeCate.categoryLanguage }}</div>
      </a>
    </li>
  </ul>
</template>

<script setup>
import { ref, defineProps, onActivated, nextTick, defineEmits } from 'vue'
import { CLSSIFY_DESIGN_WIDTH, CLSSIFY_MARK_DESIGN_WIDTH } from 'public/src/pages/product_app/store/modules/category/const.js'
import { preloadGoodsRequest } from 'public/src/pre_requests/modules/productList/spaForHydration.js'
import { replaceHrefSpecChar } from '@shein/common-function'
import { transfromAndCutImg } from 'public/src/services/resource'
import Monitor from 'public/src/pages/common/home_common/monitor.js'

const isBrowser = typeof window !== 'undefined'
const UA = isBrowser ? window.navigator.userAgent.toLowerCase() : ''
const isIOS = !!(UA && /iphone|ipad|ipod|ios/.test(UA)) || (isBrowser && window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)
import { mergeQueryString } from '@shein/common-function'
import { getGenerateLLinkParams } from '../utils'
import useVuex from '../hooks/useVuex'
import useAnalysis from '../hooks/useAnalysis'
import useCccLink from '../hooks/useCccLink'
import useRouter from '../hooks/useRouter'
const { lang = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
const { useMapState } = useVuex('category')

const { locals } = useMapState(['locals'])

const { getAnalysisData } = useAnalysis()
const { router } = useRouter() 
const { cccLink, jumpLink } = useCccLink({
  sceneData: {
    sceneName: props.cateType
  },
  propData: props.cate || {},
})
const props = defineProps({
  cate: {
    type: Array,
    default: () => []
  },
  activeChannel: {
    type: Object,
    default: () => ({})
  },
  activeLeftContent: {
    type: Object,
    default: () => ({})
  },
  isExpandChild: {
    type: Boolean,
    default: false
  },
  compIndex: {
    type: Number,
    default: 0
  },
  cateType: {
    type: String,
    default: ''
  },
  fsFlag: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['twoCategorySelectedHandler'])
const isActive = ref(true)
onActivated(() => {
  if (!isIOS) return
  isActive.value = false
  // Safari上category切换至list再返回category时，文本省略号会不展示，以下代码触发强制重绘

  nextTick(() => {
    requestAnimationFrame(() => {
      isActive.value = true
    })
  })
})
const handleATagpreventDefault =  ($event) => {
  $event && $event.preventDefault()
}
const beforeNavChange = (_href) => {
  const cRegx = /.+-c-(\d+).+/
  let cat_id = cRegx.exec(_href)

  if (cat_id) {
    cat_id = cat_id[1]
  }

  if (['ar', 'ar-en', 'in', 'es', 'us', 'de', 'uk', 'fr', 'tw', 'en', 'hk', 'se', 'sesv', 'vn'].includes(lang)) {
    return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '') })
  } else if (['cl', 'arg', 'ru', 'il', 'ilen', 'th', 'then', 'ca', 'cafr', 'eur', 'eurfr', 'eurde', 'eurnl', 'eurpt-pt'].includes(lang)) {
    let siteFix = lang
    if (['th', 'then'].includes(lang)) siteFix = 'th'
    if (['il', 'ilen'].includes(lang)) siteFix = 'il'
    if (['ca', 'cafr'].includes(lang)) siteFix = 'ca'
    if (['eur', 'eurfr', 'eurde', 'eurnl', 'eurpt-pt'].includes(lang)) siteFix = 'eur'
    // 若链接中已含有站点，则不再添加站点

    const isSiteInclude = (url, site) => {
      const reg = new RegExp(`/${site}/`)
      return reg.test(url)
    }

    if (isSiteInclude(_href, siteFix)) {
      return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '') })
    } else {
      return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '/' + siteFix) })
    }
  }
  return replaceHrefSpecChar({ href: _href })
}
const addUrlParams = ({ url = '', pos = '', id = '' } = {}) => {
  const params = {  pageFrom: pos }

  if (id) {
    Object.assign(params, {
      adp: id
    })
  }

  url = mergeQueryString({
    mergeObj: params,
    url
  })
  url = beforeNavChange(url)
  // url = url && url.replace('%', '')

  return url
}
    
const generateUrl = ({ cate, isClick }) => {
  let url = cccLink.getLink({
    item: cate?.categoryType, 
    cateLinks: {},
    compIndex: props.compIndex, 
    index: -1,
  })
  // 拼接跳转埋点参数到url 上
  url = mergeQueryString({
    mergeObj: getGenerateLLinkParams({ 
      cate,
      cateType: props.cateType, 
      channel: props.activeChannel?.channel,
      channelIndex: props.activeChannel?.channelIndex,
      oneCateContent: props.activeLeftContent,
      compIndex: props.compIndex,
      oneCate: props.activeChannel?.oneCate,
    }),
    url
  })
  // 推荐图 需要拼接额外参数 goodsId 作为adp 列表页置顶
  if (cate.autoRecommend) {
    url = addUrlParams({ url, pos: props.cateType, id: cate.cover?.goodsId })
  }
  if (isClick) {
    if (cate.categoryType.hrefType != 'noJump') {
      emits('twoCategorySelectedHandler')
    }
    jumpLink({ url, hrefType: cate?.categoryType?.hrefType, router })
    return
  }
  return url

}
const clickItem = ({ cate, index }) => {
  Monitor.metricCategoryClick({
    tags: { 
      page: 'page_category',
      cate_level: '2',
    },
    message: 'Right level second category click'
  })
  const url = generateUrl({ cate, index, isClick: true })
  preloadGoodsRequest.click(url)
}
const onPointerOver = ({ cate, index }) =>  {
  const path = generateUrl({ cate, index, isClick: false })
  if (!path) return
  preloadGoodsRequest.triggerNotice(path)
}

const cutImg = (imgUrl, forceTransform) => {
  const { RESOURCE_SDK } = locals?.value ?? {}
  const { deviceData = '', isSupportWeb = '', sceneMap = {}, isSupprotCut = '' } = RESOURCE_SDK || {}
  if (!isSupportWeb) return imgUrl
  const cutData = {
    deviceData,
    isSupportWebp: forceTransform ? true : Boolean(isSupportWeb),
    isSupprotCut: forceTransform ? true : isSupprotCut,
    imgUrl,
    designWidth: 200,
    sceneMap,
    exp: 'unCut=0#max=200',
  }
  return transfromAndCutImg(cutData)
}

</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-new-category {
  .c-cate__classify-wrap {
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0 0 0;
    margin: 0 -0.1067rem;
    background: #fff;

    .cate-classify {
      &__item {
        width: 2.05rem;
        padding: 0 0 0 0;
        margin: 0 0.1067rem 0.4rem;
      }

      &__wrap {
        text-decoration: none;
        display: block;
      }

      &__img {
        width: 1.76rem;
        height: 1.76rem;
        margin: 0 auto;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 50%;
        background-color: #f3f3f3;
        &.border {
          border: 0.5px solid #E4E4E4;
        }
      }
      &__corner-mark {
        position: absolute;
        .right(0);
        top: 0;
        width: 0.53rem;
        height: 0.53rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      &__name {
        position: relative;
        line-height: 0.38rem;
        margin-top: 0.0533rem;
        text-align: center;
        font-weight: 400;
        font-size: 10px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        max-height: calc(0.3867rem * 3);
        width: 2.05rem;
        font-size: 0.32rem;
        text-align: center;        
        color: rgba(0, 0, 0, .6);
        &.zIndex {
          z-index: 10;
        }
      }
    }
  }
}
</style>


