const useAnalysis = () => {
  // 获取埋点信息
  const getAnalysisData = (id, { item, index = 0, prefix, delayReady = 0, ...arg } = {}, setCode = false) => {
    const data = {        // 组件所在位置
      item,               // item
      index,              
      ...arg
    }
    let code = data?.cateType || ''
    if (typeof setCode === 'boolean' && setCode) code = `${item.id}_${index}`
    if (typeof setCode === 'string') code = setCode
    return {
      id,
      code,
      prefix,
      delayReady,
      data
    }
  }
  return {
    getAnalysisData
  }
}

export default useAnalysis
