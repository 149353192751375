<template>
  <div>
    <div class="cate-new_swiper-wrapper">
      <swiper-container
        ref="swiper"
        class="cate-new_swiper"
        init="false"
        destroy-on-disconnected="false"
      >
        <swiper-slide
          v-for="(item, i) in carouselList"
          :key="i"
          class="cate-new_swiper-slide j-cate-two"
          :data-report-tag="active.channel + '-' + active.oneCate + '-' + index + '-' + cateType + '-' + i"
          :data-sa-info="analysisInfo?.saInfo"
          :data-sacontent="`${item.hrefType}_${item.hrefTarget}`"
          :data-ga-info="analysisInfo?.gaInfo"
          :data-jump-type="locationInfo?.jumpType"
          :data-href-query="locationInfo?.query"
          :data-href-url="locationInfo?.url"
          :data-name="activeOneCategoryProps?.metaData?.categoryName || activeOneCategoryProps?.metaData?.categoryLanguage"
          :data-map-id="item.id"
          :data-img-id="item.image.md5"
          :data-href-target="item?.categoryType?.hrefTarget"
          :data-href-type="item?.categoryType?.hrefType"
          :data-category-id="cate.cateId"
          :data-sa-ps="generateSaPs({position: activeChildCatePosition})"
          @click="event => clickSlide(event, item)"
        >
          <BaseImg
            class="base-img"
            :class="{
              'cate-swiper-img': true
            }"
            :placeholder="{
              width: item.image.width,
              height: item.image.height,
            }"
            :custom-ratio="getCustomRatio(item)"
            :style="bgStyle"
            :ratio="item.image.ratio"
            :img-src="item.image.src"
            img-design-width="950"
            :ada="item.ada"
            :alt="item.hrefTitle"
            :brand="brand"
            :first-screen="i < 1"
          />
        </swiper-slide>
        <div
          v-if="carouselList.length > 1"
          slot="pagination"
          :class="`j-ccc-swiper-pagination-${index}`"
        ></div>
      </swiper-container>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin'
import cateItemMixin from '../mixin/ccc-cate-item-mixin'
import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { mergeQueryString } from '@shein/common-function'
import { register } from 'swiper/element'
import expose from 'public/src/services/expose/index.js'
import { Pagination, Autoplay } from 'swiper/modules'
typeof window !== 'undefined' && register()
const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
  
export default {
  name: 'CateNewSwiper',
  components: {
    BaseImg
  },
  mixins: [mixins, cateItemMixin],
  props: {
    cate: {
      type: Object,
      default() {
        return {}
      },
    },
    cateLinks: {
      type: Object,
      default () {
        return {}
      }
    },
    active: {
      type: Object,
      default() {
        return {
          channel: '2',
          oneCate: 0,
        }
      },
    },
    cateType: {
      type: String,
      default: 'cat',
    },
    pos: {
      default: '',
      type: String
    },
    handleClose: {
      type: Function,
      default: () => {}
    },
    cardStyle: {
      type: Object,
      default: () => ({}),
    },
    swiperInjectStyle: {
      type: String,
      default: '',
    },
    sceneData: {
      type: Object,
      default: () => {}
    },
    brand: {
      type: String,
      default: 'shein',
    },
    index: {
      type: Number,
      default: 1,
    }
  },
  data() {
    const { props = {} } = this.cate || {}
    const {
      items = [],
      metaData: { carouselWay = 'order', time = 3 },
    } = props
    return {
      time,
      items,
      carouselWay, // 轮播方式
    }
  },
  computed: {
    ...mapState('category', ['cat']),
    carouselList() {
      let temp = this.items || []
      if (this.carouselWay === 'optimization') {
        const totalWeights = temp.reduce((acc, cur) => acc + Number(cur.weights || 0), 0)
        temp.forEach(i => {
          i.weights = i.weights / totalWeights + Math.random()
        })
        temp.sort((a, b) => b.weights - a.weights)
      } else if (this.carouselWay === 'random') {
        temp.sort(() => Math.round(Math.random()) * 2 - 1)
      }
      return temp || []
    },
    bgStyle() {
      return {
        background: '#e5e5e5',
        borderRadius: this.cardStyle.borderRadius || '0px',
      }
    },
    cccLink() {
      return new CccLink({
        sceneData: {
          sceneName: this.cateType
        },
        propData: this.cate || {},
      })
    },
    analysisInfo() {
      return this.cate?.props?.analysisInfo ?? {}
    },
    locationInfo() {
      return this.cate?.props?.locationInfo ?? {}
    },
    activeOneCategoryProps() {
      const { active, oneCategory } = this.cat
      return oneCategory?.[active.oneCate]?.firstFloorContent?.props ?? {}
    }
  },
  async mounted() {
    this.initSwiper()
  },
  activated() {
    this.initSwiper()
  },
  methods: {
    // 处理swiper为loop时 事件绑定的问题
    initSwiper() {
      if (typeof window === 'undefined') return
      const swiperEl = this.$refs.swiper
      if (!swiperEl) return
      const swiperParams = {
        modules: [Pagination, Autoplay],
        injectStylesUrls: [ PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'],
        injectStyles: [
          `
          .swiper-pagination{
            position: absolute;
          }
          .swiper-pagination .swiper-pagination-bullet-active{
            background: rgba(34,34,34,.8);
            border: 1px solid hsla(0,0%,100%,.8);
          }
          .swiper-pagination .swiper-pagination-bullet{
            width: 6px;
            height: 6px;
          }
          ${this.swiperInjectStyle}
        `,
        ],
        loop: true,
        pagination: {
          hideOnClick: false,
        },
        spaceBetween: 0,
        speed: 300,
        slidesPerView: 1,
        slidesPerGroup: 1,
        allowTouchMove: true,
        autoplay: true,
        enabled: true,
        on: {
          init: () => {
            this.observeSwiper()
          },
        }
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()

      if (window.requestIdleCallback) {
        requestIdleCallback(() => {
          if (!swiperEl.initialized) {
            swiperEl.initialize()
          }
        })
      } else {
        setTimeout(() => {
          if (!swiperEl.initialized) {
            swiperEl.initialize()
          }
        }, 10)
      }
    },
    observeSwiper() {
      const obInstance = new expose({ observeHide: true })
      obInstance.observe({
        elements: this.$el,
        once: false,
      }, ({ exposeDoms }) => {
        if (exposeDoms.length) {
          this.$refs?.swiper?.swiper?.autoplay?.start?.()
        } else {
          this.$refs?.swiper?.swiper?.autoplay?.pause?.()
        }
      })
    },
    clickSlide(event, imageItem) {
      if (event) {
        const analysis = this.getAnalysisInstance()
        analysis?.twoCateClickHandle({ target: event.currentTarget })
      }
      const { oneCate = 0 } = this.active || {}
      const categoryType = imageItem?.categoryType ?? {}
      let url = this.cccLink.getLink({
        item: categoryType, 
        cateLinks: this.cateLinks,
        compIndex: oneCate, 
        index: -1,
      })
      // 拼接跳转埋点参数到url 上
      url = mergeQueryString({
        mergeObj: this.getGenerateLLinkParams({ cate: this.cate?.props?.metaData, cateType: this.cateType }),
        url
      })
      if (imageItem?.hrefType != 'noJump') {
        this.handleClose()
      }
      this.jumpLink({ url, hrefType: categoryType?.hrefType })
    },
    getCustomRatio(item) {
      const { width, height } = item.image
      return `${(height * 100 / width)}%`
    }
  },
}
</script>
  <style lang="less" scoped>
  .cate-new_swiper-wrapper {
    margin-top: .32rem;
    width: 100%;
    overflow: hidden;
    margin-bottom: -1px;
    /* 设置 Swiper 容器样式 */
    .cate-new_swiper {
      display: flex;
      flex-wrap: nowrap;
    }
    /* 设置滑块 slide 样式 */
    .cate-new_swiper-slide {
      --slides-per-view: 1;
      width: calc(100% / var(--slides-per-view));
      flex-shrink: 0;
  
    }
  
    .base-img{
      width: 100%;
    }
  }
  
  </style>
  
