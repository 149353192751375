/**
 * ccc category item mixin
*/
import { mapState, mapGetters } from 'vuex'
import { parseQueryString, transformImg, mergeQueryString, replaceHrefSpecChar } from '@shein/common-function'
import service from 'public/src/pages/product_app/store/modules/category/service'
import { getGenerateSaPs, handleJoinShowUrl } from './utils'
import { isInSpa } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { markPoint } from 'public/src/services/mark/index.js'

export default {
  props: {
    activeParentCatePosition: {
      type: Number,
      defualt: 0
    },
    activeChildCatePosition: {
      type: Number,
      defualt: 0
    },
    isChildCate: {
      type: Boolean,
      defualt: false
    },
  },
  data () {
    return {
      isClicked: false,
    }
  },
  computed: {
    ...mapState('category', {
      cat: state => state.cat,
      sidecat: state => state.sidecat,
      localsSite: state => state.locals.lang,
      localsCurrency: state => state.locals.currency,
      localsLangPath: state => state.locals.langPath,
    }),
    ...mapState('category', ['categoryData']),
    ...mapGetters('category', ['getterAbtInfo']),
    abtInfo () {
      return this.getterAbtInfo({ channel: this.active.channel }) || {}
    },
  },
  filters: {
    joinShowUrl (locationInfo) {
      return handleJoinShowUrl(locationInfo)
    }
  },
  methods: {
    jumpLink({ url, hrefType, routeType = 'push', cb }) {
      if (url.indexOf('javascript:;') > -1) return

      if (isInSpa({ hrefType }) && hrefType !== 'activityNew') {
        markPoint('toNextPageClick', 'public')
        this.$router[routeType](url)
        cb && cb()
      } else {
        location.href = `${url}${url.indexOf('?') > -1 ? '&allow_cache=1' : '?allow_cache=1' }`
        cb && cb()
      }
    },
    // 生产埋点跳转链接参数
    getGenerateLLinkParams({ cate, cateType }) {
      const { query, userpath, userpathFrom = {} } = cate.locationInfo || {}
      const userpathquery = userpath ? `&srctype=category&userpath=${(cateType === 'cat' ? userpathFrom.cate : userpathFrom.shop) + userpath}` : ''
    
      const params = Object.assign({},
        parseQueryString(query + userpathquery),
        { src_module: cateType },
        { src_identifier: (`fc=${this.categoryData[this.active.channel].channelName}\`sc=${document.querySelector('.j-sa-active-one')?.getAttribute('data-name') || 0}\`tc=0\`oc=${cate.categoryLanguage || 0}\`ps=${this.generateSaPs({ position: cate?.categoryType?.position }) || 0}\`jc=${cate.analysisInfo.saContent || 0}`) },
        { src_tab_page_id: window.getSaPageInfo?.tab_page_id || '' },
        {
          productsource: this.generateProductSource({ cate, cateType })
        },
        cate.mallCodes ? { mallCode: cate.mallCodes } : {}, 
        { fromPageType: 'category' },
      )
      return params
    },
    getTwoImgThumb: service.getTwoImgThumb,
    getTwoImgThumbWidth: service.getTwoImgThumbWidth,
    generateSaPs({ position = 0 }) {
      return getGenerateSaPs({ position, active: this.active, activeParentCatePosition: this.activeParentCatePosition, activeChildCatePosition: this.activeChildCatePosition, isChildCate: this.isChildCate })
    },
    generateProductSource ({ cate = {}, cateType = '' }) {
      // generate product source for analysis
      const gaInfoArr = (cate?.analysisInfo?.gaInfo || '-`').split('-\`')
      const channelInfo = this.categoryData[this.active.channel] || {}
      const oneCateInfo = channelInfo?.child[this.active.oneCate] || {}
      const isWebLink = cate?.analysisInfo?.webLink
      return `${cateType}-${gaInfoArr[0]}-${channelInfo?.channelName || channelInfo?.name }-${oneCateInfo?.enName || oneCateInfo?.name || oneCateInfo?.firstFloorContent?.props?.metaData?.categoryLanguage || ''}-${isWebLink ? encodeURIComponent(gaInfoArr[1]) : gaInfoArr[1]}`
    },
    async twoCategorySelectedHandler ({ cate = {}, cateType = '', event = '' } = {}) {
      if (event) {
        const analysis = this.getAnalysisInstance()
        analysis?.twoCateClickHandle({ target: event.currentTarget })
      }

      const type = cate?.style?.type
      if (type === 'TEXT_EXTENSIBLE' || type == 'IMAGE_TEXT_EXTENSIBLE' ) return
      try {
        if (this.isClicked) return
        this.isClicked = true
        const { jumpType, query, userpath, userpathFrom } = cate.locationInfo
        let { url } = cate.locationInfo
        const currGoodsId = cate.goodsId
        const currentTarget = event.currentTarget

        if (!url) return this.isClicked = false

        // 只有品类图存在自动配图商品id
        if (currGoodsId) {
          url = this.addUrlParams({ url, pos: cateType, id: currGoodsId })
        } else {
          url = this.addUrlParams({ url, pos: cateType })
        }

        window.setTimeout(() => {
          // 下次一个宏任务后执行跳转 确保埋点全部上报
          const userpathquery = userpath ? `&srctype=category&userpath=${(cateType === 'cat' ? userpathFrom.cate : userpathFrom.shop) + userpath}` : ''
          const params = Object.assign({},
            parseQueryString(query + userpathquery),
            { src_module: cateType },
            { src_identifier: (`fc=${this.categoryData[this.active.channel].channelName}\`sc=${document.querySelector('.j-sa-active-one')?.getAttribute('data-name') || 0}\`tc=0\`oc=${currentTarget.dataset.name || 0}\`ps=${currentTarget.dataset.saPs || 0}\`jc=${cate.analysisInfo.saContent || 0}`) },
            { src_tab_page_id: window.getSaPageInfo?.tab_page_id || '' },
            {
              productsource: this.generateProductSource({ cate, cateType })
            },
            cate.mallCodes ? { mallCode: cate.mallCodes } : {},
            cate.hrefType ? { fromPageType: 'category' } : {},
          )


          let jumpUrl = mergeQueryString({ mergeObj: params, url })
          if (jumpUrl == decodeURI(location.pathname + location.search)) {
            // 如果是相同路由 关闭弹窗
            this.$emit('twoCategorySelectedHandler')
            // 同地址不会触发vue router任何钩子, 得手动置顶
            return window.setTimeout(() => window.scrollTo(0, 0))
          }

          jumpUrl = replaceHrefSpecChar({ href: jumpUrl })
          if (jumpType === 'redirect') return location.href = jumpUrl
          if (jumpType === 'router') {
            // spa内部跳转 重置列表组件状态
            if (this.$instanceName === 'product') {
              markPoint('toNextPageClick', 'public')
              this.$router.push({ path: jumpUrl })
            } else {
              location.href = jumpUrl
            }
          }
          this.$emit('twoCategorySelectedHandler')
        })
        this.resetIsClicked()
      } catch (e) {
        console.log(e)
        this.resetIsClicked()
      }
    },
    beforeNavChange: function (_href) {
      const cRegx = /.+-c-(\d+).+/
      let cat_id = cRegx.exec(_href)

      if (cat_id) {
        cat_id = cat_id[1]
      }

      if (['ar', 'ar-en', 'in', 'es', 'us', 'de', 'uk', 'fr', 'tw', 'en', 'hk', 'se', 'sesv', 'vn'].includes(this.localsSite)) {
        return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '') })
      } else if (['cl', 'arg', 'ru', 'il', 'ilen', 'th', 'then', 'ca', 'cafr', 'eur', 'eurfr', 'eurde', 'eurnl', 'eurpt-pt'].includes(this.localsSite)) {
        let siteFix = this.localsSite
        if (['th', 'then'].includes(this.localsSite)) siteFix = 'th'
        if (['il', 'ilen'].includes(this.localsSite)) siteFix = 'il'
        if (['ca', 'cafr'].includes(this.localsSite)) siteFix = 'ca'
        if (['eur', 'eurfr', 'eurde', 'eurnl', 'eurpt-pt'].includes(this.localsSite)) siteFix = 'eur'
        // 若链接中已含有站点，则不再添加站点
        if (this.isSiteInclude(_href, siteFix)) {
          return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '') })
        } else {
          return replaceHrefSpecChar({ href: _href.replace(/^\/pwa/, '/' + siteFix) })
        }
      }
      return replaceHrefSpecChar({ href: _href })
    },
    addUrlParams ({ url = '', pos = '', id = '' } = {}) {
      const params = {
        pageFrom: pos,
        // c 埋点不需要这个参数了
        // c: $('.activeshow').text()
      }

      if (id) {
        Object.assign(params, {
          adp: id
        })
      }

      url = mergeQueryString({
        mergeObj: params,
        url
      })
      url = this.beforeNavChange(url)
      url = url && url.replace('%', '')
      return url
    },
    isSiteInclude (url, site) {
      const reg = new RegExp(`/${site}/`)
      return reg.test(url)
    },
    getWidthPercent ({ totalWidth, width } = {}) {
      return `${(width / totalWidth) * 100}%`
    },
    resetIsClicked () {
      setTimeout(() => {
        this.isClicked = false
      }, 300)
    },
    aTagpreventDefault ($event) {
      $event && $event.preventDefault()
    },
    /**
     * 计算图片占位百分比
     * @param {Number} width 图片宽度
     * @param {Number} height 图片高度
     */
    getPlaceholderHeight(width, height) {
      return `${((height / width) * 100).toFixed(2)}%`
    },
    getAnalysisInstance() {
      var parent = this.$parent

      while (parent) {
        if (parent.analysis) {
          break
        }
        parent = parent.$parent
      }
      return parent && parent.analysis
    },
    transformImg,
    // 获取埋点信息
    getAnalysisData(id, { item, index = 0, prefix, ...arg } = {}, setCode = false) {
      const data = {
        sceneData: this.sceneData,
        propData: this.propData,
        compIndex: this.index,          // 组件所在位置
        item,                           // item
        index,                          // 当前item在组件中的位置
        ...arg
      }

      let code = ''
      if (typeof setCode === 'boolean' && setCode) code = this.code
      if (typeof setCode === 'string') code = setCode

      return {
        id,
        code,
        prefix: prefix ? prefix : this.sceneData?.pageFrom,
        data
      }
    },
  }
}
